import {
  Box,
  Flex,
  Skeleton,
  Text,
  Tooltip,
  VStack,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import AddButton from 'components/AddButton';
import HelpComponent from 'components/CustomHelp';
import ArrowLeftBlocks from 'components/Icons/ArrowLeftBlocks';
import Cross from 'components/Icons/Cross';
import transformDimensions from 'connectors/transformDimensions';
import ErrorPage from 'pages/ErrorPage';
import { STATUS } from 'projectConstants';
import { getExandSideInputStatus, handleExpandSideInput } from 'redux/BuilderModeSlice';
import {
  addDimensionItem,
  fetchDimensionItems,
  fetchIndicatorDetail,
  fetchIndicatorDetailType,
  fetchModelTime,
  geModelTimeStatus,
  getChartFilterItems,
  getDimensionItems,
  getIndicatorDetail,
  getIndicatorDetailStatus,
  getModelTime,
  getTableShimmer,
} from 'redux/IndicatorPageSlice';
import { getCurrentDimensionIndex } from 'redux/InputPageSlice';
import {
  FetchPlangPageIndicatorTableData,
  fetchBlockDrivers,
  onErrorShow,
  planPageShow,
  getBlockDetails,
  getmultiDimFiltersItems,
  getIndicatorTableData,
} from 'redux/PlanPageSlice';
import {
  FetchDataInput,
  getBaseScenario,
  getExpandValue,
  getPlannerBlock,
  getPlannerModel,
  getSelectedIndicators,
  handleToggleInputPanel,
} from 'redux/PlannerModeSlice';
import addNewDimensionItem from 'utils/AddNewDimItem';
import QuestionMarkIcon from 'components/Icons/QuestionMarkIcon';
import { AppDispatch } from 'utils/GlobalHelpers';

import Chart from './Chart';
import IndicatorTable from './IndicatorTable';
import InputTypeGroupedButton from './InputTypeGroupedButton';
import EmptyData from './EmptyData';

export interface Properties {
  refetchRequest: () => void;
}

const IndicatorPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentDriver = useSelector(getSelectedIndicators);
  const containerReference = useRef<HTMLDivElement>(null);
  const [innerHeight, setInnerHeight] = useState(0);
  const indicatorTableData = useSelector(getIndicatorTableData);
  const indicator = useSelector(getIndicatorDetail);
  const isExpand = useSelector(getExpandValue);
  const blockDetails = useSelector(getBlockDetails);
  const isSideInputExpand = useSelector(getExandSideInputStatus);
  const filteredChartItems = useSelector(getChartFilterItems);
  const multiDimFiltersItems = useSelector(getmultiDimFiltersItems);
  useLayoutEffect(() => {
    if (containerReference.current) {
      const containerHeight = containerReference.current.offsetHeight;
      const containerPadding =
        Number.parseInt(window.getComputedStyle(containerReference.current).paddingLeft, 10) +
        Number.parseInt(window.getComputedStyle(containerReference.current).paddingRight, 10);
      const heightWithoutPadding = containerHeight - containerPadding;
      setInnerHeight(heightWithoutPadding);
    }
  }, [containerReference?.current]);

  const dimensionItems = useSelector(getDimensionItems);
  const { pathname } = useLocation();
  const { modelId, blockId } = useParams();

  const indicatorDetailStatus = useSelector(getIndicatorDetailStatus);
  const tabShimmer = useSelector(getTableShimmer);
  const modelTimeStatus = useSelector(geModelTimeStatus);

  const [isMobile] = useMediaQuery('(max-width: 1024px)');
  const [isSmallScreen] = useMediaQuery('(max-height: 820px)');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const modelTime = useSelector(getModelTime);

  const currentDimensionIndex = useSelector(getCurrentDimensionIndex);
  const showPlanPage = useSelector(planPageShow);
  const baseScenario = useSelector(getBaseScenario);
  const plannerModel = useSelector(getPlannerModel);
  const plannerBlock = useSelector(getPlannerBlock);

  const chartFilterDimId = indicatorTableData?.column_headers[0].is_dimension
    ? indicatorTableData?.column_headers[0].id
    : null;

  const tooltipReference = useRef<any>(null);
  const dimensionId = currentDriver?.data_input?.dimensions[currentDimensionIndex];

  useOutsideClick({
    ref: tooltipReference,

    handler: () => {
      setIsTooltipOpen(false);
    },
  });

  const fetchModelTimeData = () => {
    dispatch(fetchModelTime(Number(modelId)));
  };

  const fetchDimItemsData = () => {
    dispatch(
      fetchDimensionItems({
        dimensionId,
        parameters: {
          scenario_id: baseScenario?.id,
        },
      }),
    );
  };

  useEffect(() => {
    if (modelId && blockId) {
      fetchModelTimeData();
    }
  }, [pathname, currentDriver]);

  useEffect(() => {
    if (currentDriver && currentDriver?.data_input?.dimensions?.length > 0) {
      fetchDimItemsData();
    }
  }, [pathname, currentDriver?.id, baseScenario?.id, dimensionId]);

  useEffect(() => {
    if (modelId && blockId && currentDriver?.indicator_type.toLowerCase() === 'input') {
      dispatch(
        FetchPlangPageIndicatorTableData({
          indicator_id: currentDriver?.id,
          parameters: {
            scenario_id: baseScenario?.id,
            format_excel_data: true,
          },
        }),
      ).then(() => {
        dispatch(
          fetchIndicatorDetail({
            currentDriver,
            parameters: {
              dim_id: chartFilterDimId,
              scenario_id: baseScenario?.id,
            },
          }),
        );
      });
    }
  }, [currentDriver, baseScenario?.id, blockDetails]);

  const [noDimChartData, setNoDimChartData] = useState<Record<string, Record<string, number>> | []>(
    [],
  );
  useEffect(() => {
    if (multiDimFiltersItems && multiDimFiltersItems.length > 0) {
      dispatch(
        fetchIndicatorDetailType({
          currentDriver: indicator,
          parameters: {
            dim_id: chartFilterDimId,
            scenario_id: baseScenario?.id,
            is_chart_show: true,
            filter_dim_items: JSON.stringify(filteredChartItems),
          },
        }),
      ).then(() => {
        dispatch(
          fetchIndicatorDetail({
            currentDriver: indicator,
            parameters: {
              dim_id: chartFilterDimId,
              scenario_id: baseScenario?.id,
              is_chart_show: true,
              filter_dim_items: JSON.stringify(filteredChartItems),
            },
          }),
        );
      });
    }
  }, [indicator?.input[0].type]);

  useEffect(() => {
    if (indicator && !indicator?.output?.dims) {
      const object: any = {};
      object[indicator.output?.name] = indicator.output?.total_values;
      setNoDimChartData(object);
    }
  }, [indicator]);

  useEffect(() => {
    if (indicatorDetailStatus === STATUS.FAILED || modelTimeStatus === STATUS.FAILED) {
      dispatch(onErrorShow(true));
    }
  }, [indicatorDetailStatus, modelTimeStatus]);

  const commonObjects =
    plannerBlock?.dimensions?.length > 1
      ? plannerModel?.dimensions?.filter((object1: any) =>
          plannerBlock?.dimensions?.some(
            (object2: any) => object1.id === object2.id && object1.name !== 'Time',
          ),
        )
      : plannerModel?.dimensions?.filter((object1: any) =>
          plannerBlock?.dimensions?.some((object2: any) => object1.id === object2.id),
        );

  const closeSection = () => {
    dispatch(handleToggleInputPanel());
    dispatch(handleExpandSideInput(false));
  };

  const showShimmer =
    indicatorDetailStatus !== STATUS.SUCCESS ||
    tabShimmer === true ||
    modelTimeStatus !== STATUS.SUCCESS;

  if (indicatorDetailStatus === STATUS.FAILED || modelTimeStatus === STATUS.FAILED) {
    return (
      <>
        {isMobile && (
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            p={4}
            backgroundColor={'transparent'}
          >
            <Flex onClick={closeSection} alignItems='center' cursor='pointer'>
              {isMobile && (
                <Box mr='6px' fontFamily={'regular_roboto'}>
                  <ArrowLeftBlocks />
                </Box>
              )}
              {isMobile ? (
                <Text size='assistant_md'>Inputs</Text>
              ) : (
                <Flex alignItems={'center'}>
                  <Text ml={3} size='assistant_md'>
                    {indicator?.output?.name}
                  </Text>
                </Flex>
              )}
            </Flex>
            <Cross height={'25px'} width={'25px'} onClick={closeSection} />
          </Flex>
        )}
        <Flex alignItems={'center'} justifyContent={'center'} height={'inherit'}>
          <ErrorPage style={{ height: `calc(100vh - 83px)` }} planError={true} />
        </Flex>
      </>
    );
  }

  if (currentDriver?.indicator_type.toLowerCase() === 'calculation' || !currentDriver) {
    return (
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        height={'inherit'}
        maxH={'100%'}
      >
        <Flex w={'100%'} justify={'space-between'} pt={3} pe={2}>
          <Text ml={3} size='assistant_md'>
            {indicator?.output?.name}
          </Text>
          <Cross height={'25px'} width={'25px'} onClick={closeSection} />
        </Flex>
        <EmptyData />
      </Flex>
    );
  }

  const refetchDrivers = async () => {
    await dispatch(
      fetchBlockDrivers({
        blockId,
        parameters: {
          scenario_id: baseScenario?.id,
        },
      }),
    );
  };

  const refetchData = async () => {
    dispatch(
      fetchIndicatorDetail({
        currentDriver,
        parameters: {
          dim_id: chartFilterDimId,
          scenario_id: baseScenario?.id,
        },
      }),
    ).then(() => {
      fetchModelTimeData();
      if (currentDriver && currentDriver?.data_input?.dimensions.length > 0) {
        fetchDimItemsData();
      }
    });
  };

  const onAddDimendionItem = async () => {
    await dispatch(
      addDimensionItem({
        dimension_id: currentDriver?.data_input?.dimensions[currentDimensionIndex],
        request_body: addNewDimensionItem(dimensionItems?.items, baseScenario?.id),
        toaster: {
          successMessage: 'Successfully added',
          errorMessage: 'An item with this name already exists',
        },
      }),
    );
    await dispatch(
      FetchDataInput({
        indicator_id: currentDriver?.id,
        parameters: {
          scenario_id: baseScenario?.id,
          format_excel_data: true,
        },
      }),
    );
    await refetchData();
  };

  return (
    <Flex
      display='flex'
      flexDirection='column'
      overflow='hidden'
      className='scroll'
      width='100%'
      height={'100%'}
      p={2}
      pb={'0 !important'}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex onClick={closeSection} alignItems='center' cursor='pointer'>
          {isMobile && (
            <Box mr='6px' fontFamily={'regular_roboto'}>
              <ArrowLeftBlocks />
            </Box>
          )}
          {isMobile ? (
            <Text size='assistant_md'>Inputs</Text>
          ) : (
            <Flex
              alignItems={'center'}
              gap={'2'}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Text ml={3} size='assistant_md'>
                {indicator?.output?.name}
              </Text>
              {indicator?.help && (
                <Tooltip
                  isOpen={isTooltipOpen}
                  hasArrow
                  arrowSize={10}
                  bg='white'
                  placement='right'
                  boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
                  label={
                    <VStack
                      bg={'white'}
                      h='inherit'
                      p='3'
                      justify='space-between'
                      maxWidth={'300px'}
                      width={'180px'}
                      alignItems={'center'}
                      alignContent={'center'}
                      gap={1}
                      zIndex={1}
                    >
                      <Text
                        mb='2.5'
                        fontFamily='bold_roboto'
                        fontSize='sm'
                        lineHeight='4'
                        noOfLines={2}
                        color='black'
                      >
                        Help
                      </Text>
                      <Text
                        size='modalContent'
                        fontFamily='regular_roboto'
                        noOfLines={3}
                        color='black'
                        fontSize='2xs'
                      >
                        {indicator?.help}
                      </Text>
                    </VStack>
                  }
                  borderRadius='4'
                  width={'-moz-min-content'}
                >
                  <Box
                    onClick={() => {
                      setIsTooltipOpen(!isTooltipOpen);
                    }}
                    ref={tooltipReference}
                    mt='1'
                  >
                    <QuestionMarkIcon height={23} width={23} color='white' />
                  </Box>
                </Tooltip>
              )}
            </Flex>
          )}
        </Flex>
        <Cross height={'25px'} width={'25px'} onClick={closeSection} />
      </Flex>

      {isMobile && (
        <Flex alignItems={'center'} mt={2}>
          <Text ml={3} size='assistant_md'>
            {indicator?.output?.name}
          </Text>
        </Flex>
      )}

      {showShimmer && (
        <Box
          h={innerHeight ? `${innerHeight}px` : '30vh'}
          pt={4}
          minH={innerHeight ? `${innerHeight}px` : '30vh'}
          mb='3'
        >
          <Skeleton startColor='white' endColor='#CCD4E8' h={'-webkit-fill-available'} />
        </Box>
      )}

      {!showShimmer && (
        <Box display={{ base: 'block' }} mb='1' ref={containerReference} height={'25vh'}>
          {indicator?.output?.dims ? (
            <Chart
              showLabel={true}
              chartLabelData={indicator?.output.total_values}
              chartData={indicator?.output?.dim_item_data}
              chartType={indicator?.output?.instrument_type}
              dimId={indicator?.output?.dims?.id}
              formatProperties={indicator?.output?.data_format_properties}
            />
          ) : (
            <Chart
              showLabel={true}
              chartLabelData={indicator?.output?.total_values}
              chartData={noDimChartData}
              chartType={indicator?.output?.instrument_type}
              formatProperties={indicator?.output?.data_format_properties}
            />
          )}
        </Box>
      )}

      <Flex
        direction='column'
        borderTopLeftRadius='base'
        borderTopRightRadius='base'
        maxHeight={isSmallScreen ? `calc(100% - 200px)` : 'auto'}
      >
        {!showPlanPage && (
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Box width={'100%'} alignItems={'center'}>
              {currentDriver?.data_input?.dimensions && commonObjects.length > 0 && (
                <InputTypeGroupedButton
                  inputDimensionsData={transformDimensions(currentDriver?.data_input?.dimensions)}
                  blockId={currentDriver?.data_input?.block_id}
                  inputType={indicator?.input[0].type}
                  indicatorId={indicator?.id}
                  refetchDrivers={refetchDrivers}
                  inputDataValues={indicator?.input[0].data_values}
                  timeDimension={currentDriver?.data_input?.time_dim}
                  timeRange={modelTime?.time_range}
                  timeRangeYears={modelTime?.time_range_years}
                  refetchRequest={refetchData}
                />
              )}
            </Box>
          </Flex>
        )}

        {showPlanPage && (
          <Box width={`${isSideInputExpand ? '30%' : '100%'}`}>
            {currentDriver?.data_input?.dimensions && commonObjects.length > 0 && (
              <InputTypeGroupedButton
                inputDimensionsData={transformDimensions(currentDriver?.data_input?.dimensions)}
                blockId={currentDriver?.data_input?.block_id}
                inputType={indicator?.input[0].type}
                indicatorId={indicator?.id}
                refetchDrivers={refetchDrivers}
                inputDataValues={indicator?.input[0].data_values}
                timeDimension={currentDriver?.data_input?.time_dim}
                timeRange={modelTime?.time_range}
                timeRangeYears={modelTime?.time_range_years}
                refetchRequest={refetchData}
              />
            )}
          </Box>
        )}

        {isMobile && commonObjects.length > 0 && (
          <>
            <Box mt='1' mb='1' className='sm-scroll'>
              <IndicatorTable
                refetchRequest={refetchData}
                isPercentage={indicator?.data_format.toLowerCase()}
              />
              {dimensionItems?.items && isExpand && <AddButton onClick={onAddDimendionItem} />}
            </Box>
            {indicatorTableData?.help && isExpand && (
              <Box mt={5} pb={3}>
                <HelpComponent helpText={indicatorTableData?.help} style={{ width: '100%' }} />
              </Box>
            )}
          </>
        )}

        {!isMobile && commonObjects.length > 0 && (
          <>
            <Box
              mt='1'
              mb='1'
              pb='.5rem'
              overflowY='auto'
              className='sm-scroll'
              minH={'max-content'}
            >
              <IndicatorTable
                refetchRequest={refetchData}
                isPercentage={indicator?.data_format.toLowerCase()}
              />
              {dimensionItems?.items && isExpand && <AddButton onClick={onAddDimendionItem} />}
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default IndicatorPage;
