import { Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import ChartIconComponent from 'components/Icons/ChartIcon';
import KPIIconComponent from 'components/Icons/KpiIcon';
import TableIcomComponent from 'components/Icons/TableIcon';
// import TextBoxIconComponent from 'components/Icons/TextBoxIcon';
import { handleWidgetType } from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import AddToDashboard from 'components/Icons/AddToDashboard';

const EmptyDashboard = () => {
  const dispatch: AppDispatch = useDispatch();

  const handleAddWidget = (widgetType: number) => dispatch(handleWidgetType(widgetType));

  return (
    <Flex
      flexDirection={'column'}
      width={'100%'}
      maxW={'400px'}
      height={'250px'}
      bg={'white'}
      color={'#787878'}
      borderRadius={'15px'}
      justifyContent={'space-around'}
      alignItems={'center'}
      p={6}
    >
      <Menu variant={'dashboarding'}>
        {({ isOpen }) => {
          return (
            <>
              <MenuButton>
                <AddToDashboard width={100} height={100} />
              </MenuButton>
              {isOpen && (
                <MenuList>
                  <MenuItem onClick={() => handleAddWidget(1)}>
                    <KPIIconComponent
                      width={'21.05px'}
                      height={'21.05px'}
                      color={'#787878'}
                      marginRight='.5rem'
                    />{' '}
                    KPI
                  </MenuItem>
                  <MenuItem onClick={() => handleAddWidget(2)}>
                    <ChartIconComponent
                      marginRight='.5rem'
                      width={'21.05px'}
                      height={'21.05px'}
                      color={'#787878'}
                    />{' '}
                    Chart
                  </MenuItem>
                  <MenuItem onClick={() => handleAddWidget(3)}>
                    <TableIcomComponent
                      marginRight='.5rem'
                      width={'21.05px'}
                      height={'21.05px'}
                      color={'#787878'}
                    />{' '}
                    Table
                  </MenuItem>
                  {/* <MenuItem onClick={() => handleAddWidget(4)}>
                    <TextBoxIconComponent
                      marginRight='.5rem'
                      width={'21.05px'}
                      height={'21.05px'}
                      color={'#787878'}
                    />{' '}
                    Text box
                  </MenuItem> */}
                </MenuList>
              )}
            </>
          );
        }}
      </Menu>
      <Text size='addToDashboard'>Add to dashboard</Text>
    </Flex>
  );
};

export default EmptyDashboard;
