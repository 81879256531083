/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  Flex,
  Input,
  useDisclosure,
  Spinner,
  MenuItem,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';
import { useRef, useMemo, useEffect, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import { useSelector, useDispatch } from 'react-redux';

import { STATUS } from 'projectConstants';
import { AppDispatch } from 'utils/GlobalHelpers';
import {
  getBlocks,
  getFetchblockStatus,
  fetchBlockIndicator,
  getWidgetData,
  handleWidgetsBlock,
} from 'redux/DashboardSlice';
import SearchIcon from 'components/Icons/SearchIcon';

const DashboardModalBlock = ({ chartMatrix, isEditKpi }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const blockDetails = useSelector(getBlocks);
  const getBlockStatus = useSelector(getFetchblockStatus);
  const [searchValue, setSearchValue] = useState('');
  const [listData, setListData] = useState(blockDetails);
  const [selectedBlock, setSelectedBlock] = useState<any>(null);
  const inputReference: any = useRef(null);
  const itemHeight = 30;
  const calculatedHeight = listData.length * itemHeight;
  const height = Math.min(calculatedHeight, 150);
  const width = 260;

  useMemo(() => {
    if (isEditKpi) {
      const chartBlockId = chartMatrix?.map((item: any) => item.block_id)[0];
      const chartId = chartMatrix?.map((item: any) => item.instrument_id)[0];
      dispatch(handleWidgetsBlock(blockDetails));
      const blockData = blockDetails?.find((item: any) => item.blockId === chartBlockId);
      setSelectedBlock(blockData?.blockName);
      if (chartId) {
        dispatch(
          handleWidgetsBlock({ blockId: blockData?.blockId, blockName: blockData?.blockName }),
        );
      }
      return blockData?.blockName;
    }
  }, [blockDetails, chartMatrix]);

  const handleBlockChange = async (data: any) => {
    setSelectedBlock(data.blockName);
    dispatch(handleWidgetsBlock(data));
    dispatch(fetchBlockIndicator(Number(data?.blockId)));
    onClose();
  };

  const Row = (properties: any) => {
    const { index, style, data } = properties;
    return (
      <Flex
        alignItems={'center'}
        borderRadius={'6px'}
        paddingInline={2}
        _hover={{ backgroundColor: '#EFF2F6' }}
        onClick={() => {
          handleBlockChange(data[index]);
        }}
        style={style}
        cursor={'pointer'}
      >
        {data[index].blockName}
      </Flex>
    );
  };

  useMemo(() => {
    if (blockDetails) {
      setListData(blockDetails);
    }
  }, [blockDetails]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const getData = setTimeout(() => {
      setListData(blockDetails.filter((item: any) => item.blockName.includes(searchValue)));
    }, 500);
    return () => clearTimeout(getData);
  }, [searchValue]);

  useEffect(() => {
    if (blockDetails) {
      setListData(blockDetails);
    }
  }, [blockDetails]);

  return (
    <Flex flexDir={'column'}>
      <Text color={'black.900'} fontWeight={'600'} mb={2}>
        Block
      </Text>
      <Menu isOpen={isOpen} onClose={onClose} strategy='fixed' isLazy variant={'primary'}>
        <MenuButton width={'100%'} onClick={isOpen ? onClose : onOpen}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            backgroundColor={'#FAFAFA'}
            border={'1px #D9D9D9 solid'}
            padding={'.5rem .8rem'}
            borderRadius={'6px'}
            color={'black.600'}
            fontSize={'2xs'}
          >
            <Text>{selectedBlock || 'Click to select block'}</Text>
            <GoChevronDown size={18} />
          </Flex>
        </MenuButton>
        <MenuList p={'1.2rem'} border={'5px solid #D9D9D9'} borderRadius={'14.21px'}>
          {getBlockStatus === STATUS.LOADING ? (
            <MenuItem>
              <Spinner size={'md'} />
            </MenuItem>
          ) : (
            <>
              <Flex alignItems={'center'} height={'32px'} marginBottom={'1.3rem'}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    h='100%'
                    children={<SearchIcon width={'28.65px'} height={'22.93px'} />}
                  />
                  <Input
                    width={'93%'}
                    color={'black.900'}
                    ref={inputReference}
                    placeholder='Search'
                    _placeholder={{ color: '#D9D9D9' }}
                    onChange={(event: any) => {
                      setSearchValue(event.target.value);
                    }}
                  />
                </InputGroup>
              </Flex>
              {blockDetails.length > 0 ? (
                <List
                  className='scroll'
                  height={height}
                  itemData={listData}
                  itemCount={listData.length}
                  itemSize={itemHeight}
                  width={width}
                >
                  {Row}
                </List>
              ) : (
                <Text>No Block Found</Text>
              )}
            </>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default DashboardModalBlock;
