interface Properties {
  height?: number;
  width?: number;
  color?: string;
}

const RightIndent = ({ height = 20, width = 20, color }: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 15H15V13.3333H0V15ZM0 4.1667V10.8333L3.3333 7.5L0 4.1667ZM6.6667 11.6667H15V10H6.6667V11.6667ZM0 0V1.66667H15V0H0ZM6.6667 5H15V3.33333H6.6667V5ZM6.6667 8.3333H15V6.6667H6.6667V8.3333Z'
        fill={color}
      />
    </svg>
  );
};

export default RightIndent;
