import React, { useEffect, useState } from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getSelectedRow } from 'redux/DashboardSlice';

type StyleKey = 'bold' | 'italic' | 'underline';

const CellFormatting = ({ updateMetric }: any) => {
  // const dispatch = useDispatch();
  const selectedRow = useSelector(getSelectedRow);

  const [selectedStyle, setSelectedStyle] = useState<StyleKey | null>(null);

  const handleStyleClick = (style: StyleKey) => {
    if (!selectedRow) {
      updateMetric(null);
    } else {
      const indicatorPayload = JSON.parse(JSON.stringify(selectedRow));
      indicatorPayload.formatting.textDecoration = style;
      updateMetric(indicatorPayload);

      setSelectedStyle((previousStyle) => (previousStyle === style ? null : style));
    }
  };

  useEffect(() => {
    if (selectedRow) {
      setSelectedStyle(selectedRow?.formatting?.textDecoration);
    }
  }, [selectedRow]);

  return (
    <Flex mb={2} justifyContent={'space-between'}>
      <Box>
        <Text color={'black.900'} fontWeight={'600'}>
          Cell formatting
        </Text>
      </Box>
      <Flex alignItems={'center'} border={'1px solid #D9D9D9'} borderRadius={'7.62px'}>
        <Text
          width={8}
          textAlign={'center'}
          cursor={'pointer'}
          fontSize={'1.2rem'}
          borderRight={'2px solid #D9D9D9'}
          color={selectedStyle === 'bold' ? 'black.900' : '#787878'}
          fontWeight={'700'}
          onClick={() => handleStyleClick('bold')}
        >
          B
        </Text>
        <Text
          textAlign={'center'}
          width={8}
          cursor={'pointer'}
          fontSize={'1.2rem'}
          borderRight={'2px solid #D9D9D9'}
          color={selectedStyle === 'italic' ? 'black.900' : '#787878'}
          fontStyle={'italic'}
          onClick={() => handleStyleClick('italic')}
        >
          I
        </Text>
        <Text
          width={8}
          textAlign={'center'}
          cursor={'pointer'}
          fontSize={'1.2rem'}
          color={selectedStyle === 'underline' ? 'black.900' : '#787878'}
          textDecoration={'underline'}
          onClick={() => handleStyleClick('underline')}
        >
          U
        </Text>
      </Flex>
    </Flex>
  );
};

export default CellFormatting;
