import React, { useState } from 'react';
import { Text, Input, Box } from '@chakra-ui/react';

const TableTitle: React.FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState('Table');

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setIsEditing(false);
    }
  };

  return (
    <Box>
      {isEditing ? (
        <Box width={'40%'}>
          <Input
            color={'black.900'}
            fontWeight={'bold'}
            fontSize={'2xl'}
            fontFamily={'regular'}
            autoFocus
            border={'none'}
            value={text}
            onChange={(event) => setText(event.target.value)}
            onKeyPress={handleKeyPress}
          />
        </Box>
      ) : (
        <Text
          my={3}
          minH={8}
          fontFamily={'regular'}
          fontSize={'2xl'}
          fontWeight={'extrabold'}
          color={'blackAlpha.900'}
          textAlign={'left'}
          onClick={handleDoubleClick}
        >
          {text}
        </Text>
      )}
    </Box>
  );
};

export default TableTitle;
