/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Text, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useEffect, useState, useRef } from 'react';

import PriviewFilterByComponent from 'pages/PlanPageForecast/Plan/Filters/PreviewFilterBy';
import { fetchDashboardChart, getChartType } from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import Spinner from 'components/Spinner';

import TimeAggregationFilter from '../table/TimeAggregationFilter';
import TimeRangeFilter from '../TimeRangeFilter';

import PieChart from './PieChart';
import BarChart from './BarChart';
import LineChart from './LineChart';
import ComboChart from './ComboChart';

const ChartCard = ({ chartData, isDashboardLayout }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const chartType = useSelector(getChartType);
  const [pieChartData, setPieChartData] = useState<any>();
  const [pieGraphData, setPieGraphData] = useState<any>();
  const [timeGranularity, setTimeGranularity] = useState('M');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (chartData?.data) {
      const chartMatrixData = chartData?.data[0];
      setPieGraphData(chartMatrixData);
      dispatch(fetchDashboardChart(chartData?.id)).then((data: any) => {
        setPieChartData(data.payload);
        setIsLoading(false);
      });
    }
  }, [chartData]);

  const isDimensionFilter = useMemo(() => {
    const filterData = pieChartData?.instrument_filters?.some(
      (item: any) => item?.filter_type === 'dimension',
    );
    return filterData || false;
  }, [pieChartData?.instrument_filters]);

  const handleTimeGranulirity = (value: any) => {
    setTimeGranularity(value);
  };

  const blockId = useMemo(() => {
    if (pieChartData?.instrument_indicators) {
      const firstMetric = pieChartData.instrument_indicators[0];
      return firstMetric ? firstMetric.block_id : null;
    }
    return null;
  }, [pieChartData?.instrument_indicators]);

  const displayChart = () => {
    switch (
      isDashboardLayout
        ? chartData?.instrument_type
        : chartType === 3
        ? 'bar'
        : chartType === 2
        ? 'line'
        : 'bar'
    ) {
      case 'pie':
        return (
          <PieChart chartFormatting={pieChartData?.formatting} graphData={pieGraphData} />
          // <PieChart chartFormatting={chartData?.formatting} />
        );
      case 'line':
        return (
          <LineChart
            timeGranularityFilter={timeGranularity}
            chartFormatting={pieChartData?.formatting}
            graphData={chartData}
            isDashboardLayout={isDashboardLayout}
          />
        );
      case 'bar':
        return (
          <BarChart
            timeGranularityFilter={timeGranularity}
            chartFormatting={pieChartData?.formatting}
            graphData={chartData}
            isDashboardLayout={isDashboardLayout}
          />
        );
      case 'combi':
        return <ComboChart />;
      default:
        return <PieChart />;
    }
  };

  return (
    <>
      <Flex
        borderRadius='15px'
        flexDirection={'column'}
        border={isDashboardLayout ? '' : '1px solid #787878'}
        boxShadow={isDashboardLayout ? '' : '0px 4px 4px rgba(0, 0, 0, 0.25)'}
        height={'100%'}
      >
        {isLoading && (
          <Flex
            height={'100%'}
            direction='column'
            alignItems='center'
            justifyContent='center'
            justify='center'
            align='center'
            w={'full'}
          >
            <Spinner styleProperties={{ my: '4vh' }} />
          </Flex>
        )}
        {!isLoading && (
          <>
            <Text
              m={2}
              fontFamily={'regular'}
              fontSize={'2xl'}
              fontWeight={'extrabold'}
              color={'blackAlpha.900'}
              textAlign={'left'}
            >
              {pieChartData?.instrument_indicators && (
                <>{pieChartData?.instrument_indicators[0]?.indicator_name || 'Indicator'}</>
              )}
            </Text>
            <Flex
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              m={3}
            >
              <Flex>
                {/* <TimeRangeFilter
              chartFilters={pieChartData?.instrument_filters}
              chartId={pieChartData?.id}
            /> */}
                {isDimensionFilter && (
                  <PriviewFilterByComponent
                    filterChartData={pieChartData}
                    graphData={pieGraphData}
                    block_id={blockId}
                  />
                )}
              </Flex>
              <Flex>
                {pieChartData?.instrument_type !== 'pie' && (
                  <TimeAggregationFilter
                    setTimeGranularityFilter={handleTimeGranulirity}
                    chartData={pieChartData}
                  />
                )}
              </Flex>
            </Flex>
            <Flex justifyContent={'center'} alignItems={'center'} height={'100%'}>
              <Box
                width={
                  pieChartData?.instrument_type === 'bar' ||
                  pieChartData?.instrument_type === 'line'
                    ? '95%'
                    : ''
                }
                height={
                  pieChartData?.instrument_type === 'bar' ||
                  pieChartData?.instrument_type === 'line'
                    ? '100%'
                    : ''
                }
              >
                {displayChart()}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default ChartCard;
