/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import 'react-grid-layout/css/styles.css';
import './index.css';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Skeleton, Image, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { PDFExport } from '@progress/kendo-react-pdf';

import {
  fetchAllCharts,
  // fetchDashboardGraph,
  getAllChartIds,
  getAllChartData,
  getDashboardWidgetType,
  handleAddAllChartData,
  resetDashboard,
  fetchDashboardChart,
  FetchModelBlocks,
  fetchDashboardGraph,
  addAllDashboardTableData,
  getDashboardSelectedScenario,
  selectDashboardScenario,
  fetchDashboardID,
  createDashboardID,
  getModelDashboardId,
  updatelayout,
  getDashboard,
} from 'redux/DashboardSlice';
import { AppDispatch, isNullOrUndefinedOrEmpty } from 'utils/GlobalHelpers';
import FullScreenLoader from 'components/FullScreenLoader';
import formatIndicatorTableData from 'utils/FormatTable';
import { fetchAllScenarios, getAllScenarios } from 'redux/ScenarioSlice';
import { getBlocks } from 'redux/ModelsSlice';
import { getUserData } from 'redux/UserSlice';

import EmptyDashboard from './EmptyDashboard';
import DashboardModal from './dashboardModal';
import DashboardingHeader from './Header';
import LayoutComponent from './LayoutComponent';

const Dashboarding = () => {
  const targetReference = useRef<any>();
  const dispatch: AppDispatch = useDispatch();
  const allChartData: any = useSelector(getAllChartData);
  const allChartIds = useSelector(getAllChartIds);
  const { modelId } = useParams();
  const scenarios = useSelector(getAllScenarios);
  const dashboardWidgetType = useSelector(getDashboardWidgetType);
  const modelDashboardId = useSelector(getModelDashboardId);
  const dashboard = useSelector(getDashboard);
  const currentUser = useSelector(getUserData);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const model = useSelector(getBlocks);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  // const layoutsData: any = Object.values(allChartData);
  const scenarioId = selectedScenario?.id;
  const defaultLayout = { lg: [], md: [], sm: [], xs: [] };
  const [loader, setLoader] = useState(true);
  const [pdfExport, setPDFExport] = useState(false);

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

  useEffect(() => {
    return () => {
      dispatch(resetDashboard());
    };
  }, []);

  useEffect(() => {
    if (modelId) {
      dispatch(fetchAllScenarios(modelId));
      axios
        .post('https://dev.api.blox.so/worker/send-message', {
          model_id: modelId,
        })
        .then(() => {
          dispatch(fetchDashboardID({ model_id: modelId })).then((response) => {
            if (!response.payload?.id) {
              dispatch(
                createDashboardID({
                  request_body: {
                    name: 'string',
                    model_id: Number(modelId),
                    fiscal_start_month:
                      model?.time_properties?.time_granularity === 'M'
                        ? moment(model?.time_properties?.starting_period).format('MMM-YY')
                        : 'Jan-24',
                    description: 'string',
                  },
                }),
              );
            }
          });
        });

      dispatch(FetchModelBlocks({ modelId: Number(modelId) }));
    }
  }, [modelId]);

  useEffect(() => {
    if (modelDashboardId) {
      dispatch(fetchAllCharts(modelDashboardId)).then((data: any) => {
        if (data?.payload?.length === 0) {
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    }
  }, [modelDashboardId]);

  useEffect(() => {
    if (!selectedScenario) {
      const baseScenario = scenarios.find((item: any) => item.is_base);
      dispatch(selectDashboardScenario(baseScenario));
    }
  }, [scenarios]);

  useEffect(() => {
    if (allChartIds?.length > 0 && scenarioId) {
      allChartIds.map((item: any) => {
        // eslint-disable-next-line default-case
        switch (item.instrument_type) {
          case 'kpi': {
            dispatch(
              handleAddAllChartData({ id: item.id, shimmer: true, failed: false, type: 'kpi' }),
            );
            dispatch(fetchDashboardChart(item.id))
              .then((data: any) => {
                setLoader(false);
                if (!data.error) {
                  const updatedPayload = JSON.parse(JSON.stringify(data.payload));
                  updatedPayload.x = Number(item.x_axis);
                  updatedPayload.y = Number(item.y_axis);
                  updatedPayload.h = item.height || 2;
                  updatedPayload.w = item.width || 2;
                  updatedPayload.shimmer = false;

                  dispatch(handleAddAllChartData(updatedPayload));
                } else {
                  dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
                }
              })
              .catch(() => {
                dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
              });
            break;
          }
          case 'table': {
            dispatch(
              handleAddAllChartData({ id: item.id, shimmer: true, failed: false, type: 'table' }),
            );
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] }))
              .then((data: any) => {
                setLoader(false);
                console.log('Check 1');
                if (!data.error) {
                  const layoutData = {
                    id: item.id,
                    x: Number(item.x_axis),
                    y: Number(item.y_axis),
                    h: item.height || 2,
                    w: item.width || 2,
                    shimmer: false,
                    instrument_type: 'table',
                  };
                  dispatch(addAllDashboardTableData({ ...data.payload, ...layoutData }));
                  const formattedTableData = formatIndicatorTableData({
                    indicatorTableData: data?.payload,
                    expandKey: '',
                    tableFilter: 'M',
                  });

                  dispatch(handleAddAllChartData({ ...formattedTableData, ...layoutData }));
                } else {
                  console.log('Check 2');
                  dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
                }
              })
              .catch(() => {
                console.log('Check 3');
                dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
              });
            break;
          }
          case 'pie': {
            dispatch(
              handleAddAllChartData({ id: item.id, shimmer: true, failed: false, type: 'pie' }),
            );
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] }))
              .then((response: any) => {
                setLoader(false);
                if (!response.error) {
                  const layoutData = {
                    id: item.id,
                    x: Number(item.x_axis),
                    y: Number(item.y_axis),
                    h: item.height || 2,
                    w: item.width || 2,
                    instrument_type: 'pie',
                    shimmer: false,
                  };
                  dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
                } else {
                  dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
                }
              })
              .catch(() => {
                dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
              });
            break;
          }
          case 'bar': {
            dispatch(
              handleAddAllChartData({ id: item.id, shimmer: true, failed: false, type: 'bar' }),
            );
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] }))
              .then((response: any) => {
                if (!response.error) {
                  setLoader(false);
                  const layoutData = {
                    id: item.id,
                    i: item.id,
                    x: Number(item.x_axis),
                    y: Number(item.y_axis),
                    h: item.height || 3,
                    w: item.width || 2,
                    instrument_type: 'bar',
                    shimmer: false,
                  };
                  dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
                } else {
                  dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
                }
              })
              .catch(() => {
                dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
              });
            break;
          }
          case 'line': {
            dispatch(
              handleAddAllChartData({ id: item.id, shimmer: true, failed: false, type: 'line' }),
            );
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] }))
              .then((response: any) => {
                if (!response.error) {
                  setLoader(false);
                  const layoutData = {
                    id: item.id,
                    x: Number(item.x_axis),
                    y: Number(item.y_axis),
                    h: item.height || 3,
                    w: item.width || 2,
                    instrument_type: 'line',
                    shimmer: false,
                  };
                  dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
                } else {
                  dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
                }
              })
              .catch(() => {
                dispatch(handleAddAllChartData({ id: item.id, shimmer: false, failed: true }));
              });
            break;
          }
        }
      });
    }
  }, [allChartIds, scenarioId]);

  const handleModify = (currentLayout: any, allLayouts: any) => {
    if (modelId && currentUser && modelDashboardId && dashboard) {
      dispatch(
        updatelayout({
          instrument_layout: allLayouts,
          id: modelDashboardId,
          description: dashboard?.description,
          name: dashboard?.name,
          user_id: dashboard?.user_id,
          model_id: dashboard?.model_id,
          created_at: dashboard?.created_at,
          fiscal_start_month: dashboard?.fiscal_start_month,
          updated_at: dashboard?.updated_at,
        }),
      ); // Dispatch to Redux
    }
  };

  const handleBreakpointChange = (newBreakpoint: string) => {
    setCurrentBreakpoint(newBreakpoint); // Optional: Update state to track the current breakpoint
  };

  if (loader) {
    return <FullScreenLoader style={{ position: 'absolute' }} height='100%' width='100%' />;
  }

  const onExport = () => {
    setPDFExport(true);
    try {
      if (targetReference.current) {
        targetReference.current.save();
        setPDFExport(false);
      }
    } catch {
      setPDFExport(false);
      console.error('Export Failed !!');
    }
  };

  const getValidatedLayout = (widget: any, widgetLayout: any, defaultMinH: number) => {
    const isKPI = widget.chart_type === 'kpi';
    return {
      ...widget,
      x: !isNullOrUndefinedOrEmpty(widgetLayout?.x) ? widgetLayout.x : widget.x ?? 0,
      y: !isNullOrUndefinedOrEmpty(widgetLayout?.y)
        ? widgetLayout.y
        : widget.y ?? Number.POSITIVE_INFINITY,
      w: !isNullOrUndefinedOrEmpty(widgetLayout?.w) ? widgetLayout.w : 3,
      // h: !isNullOrUndefinedOrEmpty(widgetLayout?.h)
      //   ? widgetLayout.h
      //   : widget.h < defaultMinH
      //   ? defaultMinH
      //   : widget.h ?? defaultMinH,
      h: !isNullOrUndefinedOrEmpty(widgetLayout?.h) ? widgetLayout.h : isKPI ? 2 : 3,
      minW: 2,
      minH: defaultMinH,
      i: widget.id,
    };
  };

  return (
    <>
      {!loader && (
        <Box height={'100%'}>
          <DashboardingHeader onExport={onExport} pdfExport={pdfExport} />
          <Box
            className='scroll'
            // height={'calc(100% - 53px)'}
            mt={2}
            mx={'-20px'}
          >
            <PDFExport paperSize='auto' margin='1cm' ref={targetReference} fileName='dashboard.pdf'>
              {Object.keys(allChartData).length > 0 && dashboard ? (
                <ResponsiveReactGridLayout
                  useCSSTransforms={true}
                  className='dashboard-grid-container'
                  draggableHandle='.dashboard-drag-header'
                  allowOverlap={false}
                  onLayoutChange={handleModify}
                  onBreakpointChange={handleBreakpointChange}
                  verticalCompact={true}
                  layouts={dashboard?.instrument_layout || {}}
                  // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                  breakpoints={{ lg: 1200, md: 768, sm: 480, xs: 0 }}
                  cols={{ lg: 12, md: 12, sm: 4, xs: 1 }}
                  preventCollision={false}
                  // cols={{ lg: 12, md: 10, sm: 10, xs: 4, xxs: 4 }}
                  rowHeight={120}
                  autoSize={true}
                  margin={{
                    lg: [10, 10],
                    md: [10, 10],
                    sm: [10, 10],
                    xs: [10, 10],
                    xxs: [10, 10],
                  }}
                >
                  {Object.keys(allChartData)
                    .filter((key: any) => allChartData[key]?.chart_type !== 'pie')
                    ?.map((key: any, index: any) => {
                      const widget = allChartData[key];
                      const minH =
                        widget.chart_type === 'kpi' || widget.chart_type === 'table' ? 2 : 2;
                      const widgetLayout: any =
                        dashboard?.instrument_layout &&
                        dashboard?.instrument_layout[currentBreakpoint]
                          ? dashboard?.instrument_layout[currentBreakpoint]?.find((item: any) => {
                              return item.i === widget.id;
                            })
                          : null;

                      const gridData = {
                        ...widget,
                        x: !isNullOrUndefinedOrEmpty(widgetLayout?.x) ? widgetLayout?.x : widget.x,
                        y: !isNullOrUndefinedOrEmpty(widgetLayout?.y) ? widgetLayout?.y : widget.y,
                        minH, // Set the minimum height here
                        minW: 2,
                        h: !isNullOrUndefinedOrEmpty(widgetLayout?.h)
                          ? widgetLayout?.h
                          : widget.h < minH
                          ? minH
                          : widget.h,
                        w: !isNullOrUndefinedOrEmpty(widgetLayout?.w)
                          ? widgetLayout?.w
                          : widget.w < 4
                          ? 4
                          : widget.w,
                        i: key,
                      };

                      return widget?.shimmer ? (
                        <Skeleton
                          variant={'pulse'}
                          startColor='white'
                          endColor='#CCD4E8'
                          h={'-webkit-fill-available'}
                          key={key}
                          data-grid={getValidatedLayout(widget, widgetLayout, minH)}
                        ></Skeleton>
                      ) : (
                        <Box
                          className='dashboard-item page-break-before'
                          key={key}
                          data-grid={getValidatedLayout(widget, widgetLayout, minH)}

                          // if this doesn't work use gridData
                        >
                          <LayoutComponent widget={widget} />
                        </Box>
                      );
                    })}
                </ResponsiveReactGridLayout>
              ) : (
                <>
                  <EmptyDashboard />
                </>
              )}
            </PDFExport>
          </Box>
        </Box>
      )}

      {dashboardWidgetType !== 0 && <DashboardModal />}
    </>
  );
};

export default Dashboarding;
