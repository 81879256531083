import {
  Tabs,
  Tab,
  Flex,
  Text,
  TabList,
  TabPanel,
  TabPanels,
  useMediaQuery,
  TabIndicator,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import BlocksTabIcon from 'components/Icons/ModelOverviewTabs/BlocksTabIcon';
import { getBuilderModeTab, handleBuilderModeTab } from 'redux/BuilderModeSlice';
import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import DataTabIcon from 'components/Icons/ModelOverviewTabs/DataTabIcon';
import { getBlocks } from 'redux/ModelsSlice';
import { Flags } from 'models/Global.interface';

import BlockList from './block';
import Dimensions from './dimensions';
import DataImport from './Import';
import Dashboarding from './dashboarding';

interface Properties {
  flags?: Flags;
}

const ModelOverviewTabs = ({ flags }: Properties) => {
  const dispatch: any = useDispatch();
  const [isTablet] = useMediaQuery('(max-width: 768px)');

  const selectedTab = useSelector(getBuilderModeTab);
  const model = useSelector(getBlocks);

  useEffect(() => {
    if (isTablet && selectedTab !== 0) {
      dispatch(handleBuilderModeTab(0));
    }
  }, [isTablet]);

  // console.log('flags?.dashboarding:', flags?.dashboarding);

  return (
    <Tabs
      pt={0}
      mt={isTablet ? 4 : 0}
      onChange={(value: number) => dispatch(handleBuilderModeTab(value))}
      index={selectedTab}
      height={`calc(100% - 81px)`}
      overflow={'hidden'}
      position={'relative'}
      variant={'unstyled'}
    >
      <TabList
        borderTop='none'
        borderLeft='none'
        borderRight='none'
        borderBottom={'2px solid #FFFFFF15'}
        borderRadius={'none'}
      >
        <Tab
          color='white'
          borderLeft='none'
          borderRight='none'
          _selected={{
            backgroundColor: '#504599',
            borderTopRadius: '10px',
          }}
          fontSize={isTablet ? 'sm' : 'lg'}
          fontFamily='regular_roboto'
          maxWidth={'170px'}
          padding={2}
          height={isTablet ? '48px' : '42px'}
        >
          <Flex alignItems={'center'} justifyContent={'center'}>
            <BlocksTabIcon color={selectedTab === 0 ? '#4BB6B9' : 'white'} />
            <Text marginLeft={2}>Blocks</Text>
          </Flex>
        </Tab>
        {!isTablet && (
          <Tab
            color='white'
            borderLeft='none'
            borderRight='none'
            _selected={{
              backgroundColor: '#504599',
              borderTopRadius: '10px',
            }}
            fontSize={isTablet ? 'sm' : 'lg'}
            fontFamily='regular_roboto'
            maxWidth={'180px'}
            padding={2}
            height={isTablet ? '48px' : '42px'}
          >
            <Flex alignItems={'center'} justifyContent={'center'}>
              <DimensionTabIcon
                width={7}
                height={6}
                color={selectedTab === 1 ? '#4BB6B9' : 'white'}
              />
              <Text marginLeft={2}>Dimensions</Text>
            </Flex>
          </Tab>
        )}
        {!isTablet && (
          <Tab
            color='white'
            borderLeft='none'
            borderRight='none'
            _selected={{
              backgroundColor: '#504599',
              borderTopRadius: '10px',
            }}
            fontSize={isTablet ? 'sm' : 'lg'}
            fontFamily='regular_roboto'
            maxWidth={'180px'}
            padding={1}
            height={isTablet ? '48px' : '42px'}
          >
            <Flex alignItems={'center'} justifyContent={'center'}>
              <DataTabIcon color={selectedTab === 2 ? '#4BB6B9' : 'white'} />
              <Text marginLeft={2}>Data</Text>
            </Flex>
          </Tab>
        )}
        {flags?.dashboarding && !isTablet && (
          <Tab
            color='white'
            borderLeft='none'
            borderRight='none'
            _selected={{
              backgroundColor: '#504599',
              borderTopRadius: '10px',
            }}
            fontSize={isTablet ? 'sm' : 'lg'}
            fontFamily='regular_roboto'
            maxWidth={'170px'}
            padding={2}
            height={isTablet ? '48px' : '42px'}
          >
            <Flex alignItems={'center'} justifyContent={'center'}>
              <BlocksTabIcon color={selectedTab === 3 ? '#4BB6B9' : 'white'} />
              <Text marginLeft={2}>Dashboard</Text>
            </Flex>
          </Tab>
        )}
      </TabList>
      <TabIndicator
        marginTop='-1.5px'
        style={{
          marginTop: '-1.5px',
          height: '3px',
          backgroundColor: '#4BB6B9',
          borderRadius: '10px',
        }}
      />
      <TabPanels
        height={'calc(100% - 49px)'}
        pt={isTablet ? 4 : 7}
        px={isTablet ? 4 : 7}
        overflowY={'auto'}
        className={'scroll'}
      >
        <TabPanel p={0} height={'100%'}>
          {selectedTab === 0 && <BlockList blocks={model?.blocks} />}
        </TabPanel>
        <TabPanel p={0} height={'100%'}>
          {selectedTab === 1 && <Dimensions />}
        </TabPanel>
        <TabPanel p={0} height={'100%'}>
          {selectedTab === 2 && <DataImport />}
        </TabPanel>
        {/* <TabPanel p={0} height={'100%'} overflow={'hidden'}> */}
        <TabPanel p={0} height={'100%'}>
          {selectedTab === 3 && <Dashboarding />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default withLDConsumer()(ModelOverviewTabs);
