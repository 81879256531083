import {
  Button,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  Divider,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';

import MultiSelectCheckBox from 'components/MultiSelectCheckBox';
import {
  // fetchAnalysisSectionData,
  FetchBlockItemDimensions,
  getBlockDimensions,
  getDimension,
  getSelectedBlockDimension,
  handleFilters,
  overallGroupByFilter,
  overallIndicatorFilter,
  overallTimeFilter,
  resetSelectedBlockDimension,
  setMultiDimFilter,
  setFilterItems,
  getIndicatorTableData,
  // fetchTableData,
  getAppliedFilters,
  fetchAnalysisSectionDataVersion2,
} from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/PlannerModeSlice';
import { GetInputAdjustments } from 'redux/driverAdjustmentSlice';
import { indicatorfiltersName, getTableFilterPayload } from 'utils/GlobalHelpers';
import {
  getBaseScenario as planPageBaseScenario,
  getCompareScenario,
  fetchCompareTableData,
  fetchCompareAnalysisSectionData,
} from 'redux/ScenarioSlice';
import {
  fetchIndicatorDetail,
  fetchIndicatorDetailType,
  getIndicatorDetail,
} from 'redux/IndicatorPageSlice';

const MultipleIndicatorFilters = () => {
  const { blockId } = useParams();
  const dispatch: any = useDispatch();

  const [clearButton, setClearButton] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const indicatorTableData = useSelector(getIndicatorTableData);
  const blockDimensions = useSelector(getBlockDimensions);
  const compareScenario = useSelector(getCompareScenario);
  const indicator = useSelector(getIndicatorDetail);
  const selectedBlockDimension = useSelector(getSelectedBlockDimension);
  const dimension = useSelector(getDimension);
  const selectedYear = useSelector(overallTimeFilter);
  const selectedGroupBy = useSelector(overallGroupByFilter);
  const baseScenario = useSelector(getBaseScenario);
  const inputAdjustments = useSelector(GetInputAdjustments);
  const indicatorFilter = useSelector(overallIndicatorFilter);
  const planPageScenario = useSelector(planPageBaseScenario);
  const appliedFilters = useSelector(getAppliedFilters);
  const [isChartFilter, setIsChartFilter] = useState<any>(false);

  const scenarioId = baseScenario?.id;
  const chartFilterDimId = indicatorTableData?.column_headers[0]?.is_dimension
    ? indicatorTableData?.column_headers[0].id
    : null;

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    dispatch(
      FetchBlockItemDimensions({
        blockId,
        parameters: {
          params: { scenario_id: planPageScenario?.id },
        },
      }),
    );
  }, []);

  const fetchOutputData = () => {
    if (!compareScenario) {
      dispatch(
        fetchAnalysisSectionDataVersion2({
          blockId: Number(blockId),
          parameters: {
            dim_id:
              selectedGroupBy === 'Total'
                ? null
                : dimension && !selectedGroupBy
                ? dimension?.id
                : selectedGroupBy
                ? Number.parseInt(selectedGroupBy, 10)
                : null,
            dim_sort: 'ASC',
            time_filter: selectedYear || null,
            time_aggregation: 'Y,Q,M',
            indicator_filter: indicatorFilter,
            ...(Object.keys(inputAdjustments).length > 0 && {
              input_adjustments: inputAdjustments,
            }),
            scenario_id: baseScenario?.id,
            dim_filter: JSON.stringify(selectedBlockDimension),
          },
        }),
      );
      // dispatch(
      //   fetchTableData({
      //     blockId: Number(blockId),
      //     parameters: {
      //       params: {
      //         dim_id:
      //           selectedGroupBy === 'Total'
      //             ? null
      //             : dimension && !selectedGroupBy
      //             ? dimension?.id
      //             : selectedGroupBy
      //             ? Number.parseInt(selectedGroupBy, 10)
      //             : null,
      //         dim_sort: 'ASC',
      //         time_filter: selectedYear || null,
      //         time_aggregation: 'Y,Q,M',
      //         indicator_filter: indicatorFilter,
      //         ...(Object.keys(inputAdjustments).length > 0 && {
      //           input_adjustments: inputAdjustments,
      //         }),
      //         scenario_id: baseScenario?.id,
      //         dim_filter: JSON.stringify(selectedBlockDimension),
      //       },
      //     },
      //   }),
      // );
    } else {
      dispatch(
        fetchCompareAnalysisSectionData({
          blockId: Number(blockId),
          parameters: {
            params: {
              dim_id:
                selectedGroupBy === 'Total'
                  ? null
                  : dimension && !selectedGroupBy
                  ? dimension?.id
                  : selectedGroupBy
                  ? Number.parseInt(selectedGroupBy, 10)
                  : null,
              dim_sort: 'ASC',
              time_filter: selectedYear || null,
              time_aggregation: 'Y,Q,M',
              indicator_filter: indicatorFilter,
              ...(Object.keys(inputAdjustments).length > 0 && {
                input_adjustments: inputAdjustments,
              }),
              scenario_id: planPageScenario?.id,
              compare_scenario_id: compareScenario?.id,
              dim_filter: JSON.stringify(selectedBlockDimension),
            },
          },
        }),
      );
      dispatch(
        fetchCompareTableData({
          blockId: Number(blockId),
          parameters: {
            params: {
              dim_id:
                selectedGroupBy === 'Total'
                  ? null
                  : dimension && !selectedGroupBy
                  ? dimension?.id
                  : selectedGroupBy
                  ? Number.parseInt(selectedGroupBy, 10)
                  : null,
              dim_sort: 'ASC',
              time_filter: selectedYear || null,
              time_aggregation: 'Y,Q,M',
              indicator_filter: indicatorFilter,
              ...(Object.keys(inputAdjustments).length > 0 && {
                input_adjustments: inputAdjustments,
              }),
              scenario_id: planPageScenario?.id,
              compare_scenario_id: compareScenario?.id,
              dim_filter: JSON.stringify(selectedBlockDimension),
            },
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (clearButton) {
      fetchOutputData();
    }
  }, [clearButton]);

  const applyIndicatorTableFilters = () => {
    const activeDimIds = indicatorTableData?.dimensions.map((item: any) => item.id);

    // eslint-disable-next-line unicorn/no-for-loop, unicorn/prevent-abbreviations, no-plusplus, no-lone-blocks, @typescript-eslint/no-unused-vars
    for (let i = 0; i < selectedBlockDimension.length; i++) {
      if (
        Object.values(selectedBlockDimension[i] as Record<string, any>)[0].length > 0 &&
        activeDimIds?.includes(String(blockDimensions?.dimensions[i].id))
      ) {
        const payload = getTableFilterPayload(
          selectedBlockDimension[i],
          blockDimensions?.dimensions[i],
        );
        dispatch(setFilterItems(payload.dimensionItems)); // for filter dropdown
        dispatch(setMultiDimFilter(payload.tableItems)); // for table items
      }
    }
  };

  useEffect(() => {
    if (appliedFilters && appliedFilters.length > 0 && isChartFilter) {
      dispatch(
        fetchIndicatorDetailType({
          currentDriver: indicator,
          parameters: {
            dim_id: chartFilterDimId,
            scenario_id: scenarioId,
            is_chart_show: true,
            filter_dim_items: JSON.stringify(appliedFilters),
          },
        }),
      ).then(() => {
        dispatch(
          fetchIndicatorDetail({
            currentDriver: indicator,
            parameters: {
              dim_id: chartFilterDimId,
              scenario_id: scenarioId,
              is_chart_show: true,
              filter_dim_items: JSON.stringify(appliedFilters),
            },
          }),
        );
      });
      setIsChartFilter(false);
    }
  }, [isChartFilter]);

  const handleApplyFilter = () => {
    setIsChartFilter(true);
    dispatch(handleFilters());
    setIsMenuOpen(false);
    fetchOutputData();
    applyIndicatorTableFilters();
  };

  const handleClearAll = async () => {
    setIsMenuOpen(false);
    setClearButton(true);
    await dispatch(resetSelectedBlockDimension());
    setClearButton(false);
  };

  return (
    <Menu
      variant={'filter'}
      isOpen={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      onOpen={() => setIsMenuOpen(true)}
      placement='bottom-end'
      strategy='fixed'
    >
      <MenuButton
        as={Button}
        paddingRight='2'
        paddingLeft={'2'}
        height={isMobile ? '25px' : '30px'}
        width='fit-content'
        textTransform='none'
        fontWeight='400'
        fontSize={isMobile ? 'xs' : 'sm'}
        color={'white'}
        border='none'
        bg='transaparant'
        boxShadow='none'
      >
        <Flex>
          <Text size={'Roboto-12'} color={'rgba(255, 255, 255, 0.6)'} mr={2}>
            Filter by:
          </Text>

          {selectedBlockDimension &&
            indicatorfiltersName(selectedBlockDimension, blockDimensions?.dimensions)?.map(
              (item: any, index: number) => (
                <React.Fragment key={index}>
                  {item && (
                    <>
                      <Divider
                        mx={2}
                        orientation='vertical'
                        size='2xs'
                        colorScheme='grey'
                        height='12px'
                        alignSelf='center'
                      />
                      <Text size={'Roboto-12'} whiteSpace={'nowrap'}>
                        {item}
                      </Text>
                    </>
                  )}
                </React.Fragment>
              ),
            )}

          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      {/* {isOpen && ( */}
      <MenuList>
        <Flex
          gap={8}
          maxW={isMobile ? '300px' : '600px'}
          overflowX={'auto'}
          className='sm-scroll'
          pb={4}
        >
          {blockDimensions?.dimensions?.map((checkBoxItem: any, index: number) => (
            <React.Fragment key={index}>
              <MultiSelectCheckBox
                clearButton={clearButton}
                checkboxData={checkBoxItem}
                isMenuOpen={isMenuOpen}
              />
            </React.Fragment>
          ))}
        </Flex>
        <Flex justifyContent={'space-between'} mt={4}>
          {blockDimensions?.dimensions?.length > 1 && (
            <Button variant={'clear_button'} onClick={handleClearAll}>
              Clear all
            </Button>
          )}
          <Flex>
            <Button variant={'cancel_button'} mr={3} onClick={() => setIsMenuOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleApplyFilter} variant={'save_button'}>
              Apply
            </Button>
          </Flex>
        </Flex>
      </MenuList>
    </Menu>
  );
};

export default MultipleIndicatorFilters;
