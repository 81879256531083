import React, { useState } from 'react';
import { Box, Menu, MenuButton, MenuList, MenuItem, Flex, Image } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { DragHandleIcon } from '@chakra-ui/icons';

import Pensil from 'components/Icons/Pencil';
import InfoIconComponent from 'components/Icons/InfoIcon';
import {
  handleIsEditModal,
  fetchDashboardGraph,
  handleAddCurrentModalChart,
  handleWidgetType,
  handleChartType,
  fetchDashboardChart,
  // getAllChartData,
  handleDeleteDashboardChart,
  deleteChart,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import warningIcon from 'assets/action_error_icon.png';

import KPICard from './kpi/KpiCard';
import DashboardTable from './table/DashboardTable';
import ChartCard from './chart/ChartCard';
// import KPICard from './kpi/KpiCard';

interface Properties {
  widget: any;
}
const LayoutComponent = ({ widget }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  // const allChartData = useSelector(getAllChartData);
  const [hover, setHover] = useState<boolean>(false);
  const handleEditClick = (chartType: string, chartId: string) => {
    dispatch(handleAddCurrentModalChart(chartId));
    dispatch(handleIsEditModal(true));
    switch (chartType) {
      case 'kpi':
        dispatch(handleWidgetType(1));
        dispatch(fetchDashboardChart(chartId));
        dispatch(handleIsEditModal(true));
        break;
      // case 'pie':
      //   dispatch(handleIsEditModal(true));
      //   dispatch(handleWidgetType(2));
      //   dispatch(handleChartType(1));
      //   dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
      //   dispatch(fetchDashboardChart(chartId));
      //   break;
      case 'bar':
        dispatch(handleIsEditModal(true));
        dispatch(handleWidgetType(2));
        dispatch(handleChartType(3));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        dispatch(fetchDashboardChart(chartId));
        break;
      case 'line':
        dispatch(handleIsEditModal(true));
        dispatch(handleWidgetType(2));
        dispatch(handleChartType(2));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        dispatch(fetchDashboardChart(chartId));
        break;
      case 'table':
        dispatch(handleWidgetType(3));
        dispatch(
          fetchDashboardGraph({
            chartId,
            scenarioId,
            requestBody: [],
          }),
        );
        dispatch(fetchDashboardChart(chartId));
        break;
      default:
        break;
    }
  };

  const handleDelete = (id: string) => {
    dispatch(deleteChart({ chartId: id })).then(() => {
      dispatch(handleDeleteDashboardChart(id));
    });
  };
  return (
    <Box
      height='calc(100% - 40px)'
      padding={1}
      position={'relative'}
      zIndex={'1'}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Flex visibility={hover ? 'visible' : 'hidden'} justifyContent={'space-between'} mb={1}>
        <button className='dashboard-drag-header'>
          <DragHandleIcon />
        </button>

        <Flex gap={1} alignItems={'center'}>
          {!widget?.failed && (
            <Box
              cursor={'pointer'}
              onClick={() => handleEditClick(widget.instrument_type, widget.id)}
            >
              <Pensil />
            </Box>
          )}

          <Box>
            <Menu closeOnSelect={true} strategy='fixed' variant={'primary'}>
              <MenuButton>
                <InfoIconComponent />
              </MenuButton>
              <MenuList>
                {!widget?.failed && (
                  <MenuItem onClick={() => handleEditClick(widget.instrument_type, widget.id)}>
                    Edit
                  </MenuItem>
                )}
                <MenuItem onClick={() => handleDelete(widget.id)}>Delete</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Flex>

      {widget?.failed && (
        <Flex height='100%' width={'100%'} justifyContent={'center'} alignItems={'center'}>
          <Image src={warningIcon} height={20} />
        </Flex>
      )}

      {!widget?.failed && (
        <Box height='100%'>
          {widget.instrument_type === 'kpi' && (
            <KPICard chartData={widget} isDashboardLayout={true} />
          )}
          {/* {widget.instrument_type === 'pie' && <ChartCard chartData={widget} isDashboardLayout={true} />} */}
          {widget.instrument_type === 'bar' && (
            <ChartCard chartData={widget} isDashboardLayout={true} />
          )}
          {widget.instrument_type === 'line' && (
            <ChartCard chartData={widget} isDashboardLayout={true} />
          )}
          {widget.instrument_type === 'table' && (
            <DashboardTable
              tableData={widget.tableData}
              tableHeaderData={widget.tableHeaderData}
              expandkey={widget.expandKey}
              tableFilter={widget.tableFilter}
              isDashboardLayout={true}
              chartId={widget.id}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default LayoutComponent;
