import React, { useEffect, useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import LeftIndent from 'components/Icons/LeftIndent';
import RightIndent from 'components/Icons/RightIndent';
import { getSelectedRow } from 'redux/DashboardSlice';

const Indentation = ({ updateMetric }: any) => {
  const selectedRow = useSelector(getSelectedRow);
  const [selectedIndentation, setSelectedIndentation] = useState('');

  useEffect(() => {
    if (selectedRow) {
      setSelectedIndentation(selectedRow?.formatting?.textAlign);
    }
  }, [selectedRow]);

  const changeTextAlign = (alignProperty: string) => {
    if (!selectedRow) {
      updateMetric(null);
    } else {
      setSelectedIndentation(alignProperty);

      const indicatorPayload = JSON.parse(JSON.stringify(selectedRow));
      indicatorPayload.formatting.textAlign = alignProperty;
      updateMetric(indicatorPayload);
    }
  };
  return (
    <Flex alignItems={'center'} mb={2} justifyContent={'space-between'}>
      <Text color={'black.900'} fontWeight={'600'}>
        Indent
      </Text>
      <Flex gap={3}>
        <Box cursor={'pointer'} onClick={() => changeTextAlign('left')}>
          <LeftIndent color={selectedIndentation === 'left' ? '#000' : '#787878'} />
        </Box>
        <Box cursor={'pointer'} onClick={() => changeTextAlign('right')}>
          <RightIndent color={selectedIndentation === 'right' ? '#000' : '#787878'} />
        </Box>
      </Flex>
    </Flex>
  );
};

export default Indentation;
