const MaximizeIcon = () => {
  return (
    <svg
      fill='#ffff'
      height='14px'
      width='12px'
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlns-xlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 242.133 242.133'
      xml-space='preserve'
    >
      <g id='XMLID_25_'>
        <path
          id='XMLID_26_'
          d='M89.247,131.673l-47.732,47.73l-15.909-15.91c-4.29-4.291-10.742-5.572-16.347-3.252
        C3.654,162.563,0,168.033,0,174.1v53.032c0,8.284,6.716,15,15,15l53.033,0.001c0.007-0.001,0.012-0.001,0.019,0
        c8.285,0,15-6.716,15-15c0-4.377-1.875-8.316-4.865-11.059l-15.458-15.458l47.73-47.729c5.858-5.858,5.858-15.355,0-21.213
        C104.603,125.815,95.104,125.816,89.247,131.673z'
        />
        <path
          id='XMLID_28_'
          d='M227.133,0H174.1c-6.067,0-11.536,3.655-13.858,9.26c-2.321,5.605-1.038,12.057,3.252,16.347l15.911,15.911
        l-47.729,47.73c-5.858,5.858-5.858,15.355,0,21.213c2.929,2.929,6.768,4.393,10.606,4.393c3.839,0,7.678-1.464,10.606-4.394
        l47.73-47.73l15.909,15.91c2.869,2.87,6.706,4.394,10.609,4.394c1.933,0,3.882-0.373,5.737-1.142
        c5.605-2.322,9.26-7.792,9.26-13.858V15C242.133,6.716,235.417,0,227.133,0z'
        />
      </g>
    </svg>
  );
};

export default MaximizeIcon;
