/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Tooltip,
  Divider,
  useToast,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { GoChevronDown } from 'react-icons/go';
import { useEffect, useMemo, useState } from 'react';

import CustomToast from 'components/CustomToast';
import Plus from 'components/Icons/Plus';
import {
  getDimensionFilters,
  getDimensions,
  getWidgetData,
  fetchDashboardChart,
  handleAddFilter,
  updateChartFilter,
  handleDimFilter,
  addChartFilter,
  getBlock,
  deleteChartFilter,
  getKpiChartData,
  fetchDashboardGraph,
  getIndicatorDimensions,
  getChartType,
  getDashboardChartId,
  getIndicator,
  getDashboardWidgetType,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { getAllScenarios } from 'redux/ScenarioSlice';
import RemoveIconComponent from 'components/Icons/RemoveIcon';
import { AppDispatch } from 'utils/GlobalHelpers';
import DimensionIconComponent from 'components/Icons/DimensionIcon';

interface Properties {
  chartFilter?: any;
  isEditKpi?: any;
}

const DashboardModalFilters = ({ chartFilter, isEditKpi }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const dimensions = useSelector(getDimensions);
  const indicatorDimension = useSelector(getIndicatorDimensions);
  const indicatorData = useSelector(getIndicator);

  const chartId = useSelector(getDashboardChartId);
  const toast = useToast();
  const selectedBlock = useSelector(getBlock);
  const widgetData = useSelector(getWidgetData);
  const widgetType = useSelector(getDashboardWidgetType);
  const kpiChartData = useSelector(getKpiChartData);
  const dimentionsFilters: any = useSelector(getDimensionFilters);
  const [myDim, setDims] = useState(widgetType !== 2 ? dimensions : indicatorDimension);
  const [selectedDimFilter, setSelectedDimFilter] = useState<any[]>([]);

  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const chartType = useSelector(getChartType);

  useEffect(() => {
    setSelectedDimFilter(dimentionsFilters);
  }, [dimentionsFilters]);

  useEffect(() => {
    if (dimensions) setDims(widgetType !== 2 ? dimensions : indicatorDimension);
  }, [dimensions, indicatorDimension]);

  const dimData = useMemo(() => {
    if (dimensions && chartFilter && isEditKpi) {
      const chartSelectedFilter = chartFilter?.map((filter: any, index: any) => {
        const dimension = dimensions?.find((dim1: any) => dim1.dimension_name === filter.name);
        const item = dimension?.dimension_items?.find(
          (dimItem: any) => dimItem.dimension_item_name === filter.value,
        );
        return {
          localId: index + 1,
          dimensionId: dimension?.dimension_id,
          dimensionName: dimension?.dimension_name,
          dimensionItems: [item],
        };
      });
      setSelectedDimFilter(chartSelectedFilter);
      dispatch(handleAddFilter(true));
      return chartSelectedFilter;
    }
  }, [chartFilter]);

  const dimFilter = useMemo(() => {
    if (kpiChartData) {
      const chartDataId = kpiChartData?.instrument_filters
        ?.map((item: any) => {
          if (item.filter_type === 'dimension') {
            return {
              id: item.id,
              dim_name: item.name,
            };
          }
        })
        .filter((item: any) => item !== undefined);
      return chartDataId;
    }
    return null;
  }, [kpiChartData]);

  const handleAddingFilter = () => {
    setSelectedDimFilter([
      ...selectedDimFilter,
      {
        localId: selectedDimFilter.length + 1,
        dimensionId: 0,
        dimensionItems: [],
        dimensionName: 'Dimension',
      },
    ]);
    dispatch(handleAddFilter(true));
  };

  const handleRemovingFilter = (itemId: number, dimName: any) => {
    const matchingId = dimFilter?.find((item: any) => item.dim_name.includes(dimName))?.id;
    const newDimensionsFilters = selectedDimFilter.filter(
      (filter: any) => filter.localId !== itemId,
    );
    if (newDimensionsFilters.length === 0) {
      dispatch(handleAddFilter(false));
    }
    dispatch(deleteChartFilter(matchingId)).then((response: any) => {
      if (response.type.endsWith('/fulfilled')) {
        toast({
          duration: 2000,
          render: () => <CustomToast title={response?.payload.message} status='success' />,
        });
        dispatch(fetchDashboardChart(chartId));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
      }
    });
    setSelectedDimFilter(newDimensionsFilters);
    dispatch(handleDimFilter(newDimensionsFilters));
  };

  const handleDimensionSelect = (dim: any, localId: any) => {
    const dimensionItems = myDim
      ?.filter((item: any) => item.dimension_id === dim.dimension_id)
      ?.map((item: any) => item.dimension_items);

    const newDimensionsFilters = selectedDimFilter.map((filter: any) =>
      filter.localId === localId
        ? {
            ...filter,
            dimensionName: dim.dimension_name,
            dimensionId: dim.dimension_id,
            dimensionItems: dimensionItems[0],
          }
        : filter,
    );
    if (widgetType === 2) {
      const dimNames = new Set(newDimensionsFilters.map((item) => item.dimensionName));
      const misMatchDimNameId = dimFilter
        ?.filter((item: any) => !dimNames.has(item.dim_name))
        .map((item: any) => item.id);
      const dimenesionMatched = dimFilter?.find(
        (item: any) => item?.dim_name === dim.dimension_name,
      );
      const filterId = dimenesionMatched
        ? dimenesionMatched?.id
        : misMatchDimNameId?.length > 0
        ? misMatchDimNameId[0]
        : null;
      const payload = {
        name: dim.dimension_name,
        operator: 'eq',
        filter_type: 'dimension',
        value: '',
        instrument_id: chartId,
      };
      if (newDimensionsFilters?.length === dimFilter?.length) {
        dispatch(updateChartFilter({ ...payload, id: filterId })).then(() => {
          dispatch(fetchDashboardChart(chartId));
          dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        });
      } else if (chartId) {
        dispatch(addChartFilter(payload)).then(() => {
          dispatch(fetchDashboardChart(chartId));
          dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        });
      }
    }
    setSelectedDimFilter(newDimensionsFilters);
    dispatch(handleDimFilter(newDimensionsFilters));
  };

  const handleItemSelect = (dimItem: any, localId: any, dimensionName: any) => {
    const newDimensionsFilters = selectedDimFilter.map((filter: any) =>
      filter.localId === localId
        ? {
            ...filter,
            dimensionItems: [dimItem],
          }
        : filter,
    );
    const dimNames = new Set(newDimensionsFilters.map((item) => item.dimensionName));
    const misMatchDimNameId = dimFilter
      ?.filter((item: any) => !dimNames.has(item.dim_name))
      .map((item: any) => item.id);
    setSelectedDimFilter(newDimensionsFilters);
    dispatch(handleDimFilter(newDimensionsFilters));
    const dimenesionMatched = dimFilter?.find((item: any) => item?.dim_name === dimensionName);
    const filterId = dimenesionMatched
      ? dimenesionMatched?.id
      : misMatchDimNameId?.length > 0
      ? misMatchDimNameId[0]
      : null;
    const payload = {
      name: dimensionName,
      operator: 'eq',
      filter_type: 'dimension',
      value: dimItem?.dimension_item_name,
      instrument_id: chartId,
    };
    if (newDimensionsFilters?.length === dimFilter?.length) {
      dispatch(updateChartFilter({ ...payload, id: filterId })).then(() => {
        dispatch(fetchDashboardChart(chartId));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
      });
    } else if (chartId) {
      dispatch(addChartFilter(payload)).then(() => {
        dispatch(fetchDashboardChart(chartId));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
      });
    }
  };

  const renderMenuItems = (isOpen: any, myDims: any, item: any) => {
    if (!isOpen) return null;

    return (
      <MenuList>
        {item?.dimensionId === 0 ? (
          <MenuItem>Select Dimension</MenuItem>
        ) : (
          myDims?.map((dimItem: any) => {
            if (item?.dimensionId === dimItem?.dimension_id) {
              const dimItems = dimItem?.dimension_items;
              return dimItems?.map((items: any) => (
                <MenuItem
                  onClick={() => handleItemSelect(items, item?.localId, dimItem?.dimension_name)}
                  key={items?.dimension_item_id}
                >
                  {items?.dimension_item_name}
                </MenuItem>
              ));
            }
            return null;
          })
        )}
      </MenuList>
    );
  };

  return (
    <Flex flexDir={'column'}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Text color={'black.900'} fontWeight={'600'}>
          Filters
        </Text>
        <Button width={'14.583px'} disabled={selectedDimFilter?.length > dimentionsFilters?.length}>
          <Plus color='#787878' onClick={handleAddingFilter} />
        </Button>
      </Flex>
      {!widgetData.filters && (
        <Flex
          cursor={'pointer'}
          onClick={handleAddingFilter}
          justifyContent={'space-between'}
          alignItems={'center'}
          backgroundColor={'#FAFAFA'}
          border={'1px #D9D9D9 solid'}
          padding={'.5rem .8rem'}
          borderRadius={'6px'}
          color={'black.600'}
          fontSize={'2xs'}
        >
          <Text>{'Click to add filter'}</Text>
        </Flex>
      )}
      {widgetData.filters && (
        <>
          <Box>
            <Flex mb={1}>
              <Text size={'Roboto-14'} color={'#B5B5B5'} width={'50%'}>
                Dimension
              </Text>
              <Text size={'Roboto-14'} color={'#B5B5B5'} width={'50%'}>
                Default filter
              </Text>
            </Flex>
            <Flex flexDir={'column'} gap={4}>
              {selectedDimFilter.map((item: any) => {
                return (
                  <Flex
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    gap={2}
                    key={item.localId}
                  >
                    <Flex
                      backgroundColor={'#FAFAFA'}
                      flex={1}
                      borderRadius={'6px'}
                      alignItems={'center'}
                      border={'1px solid'}
                      justifyContent={'space-between'}
                      borderColor={'grey.700'}
                    >
                      <Tooltip placement='top' label={item.dimensionName}>
                        <Box width={'50%'}>
                          <Menu variant={'dashboardingModal'}>
                            {({ isOpen }) => (
                              <>
                                <MenuButton
                                  as={Button}
                                  padding={'.3rem .5rem'}
                                  variant={'dashboardingModal'}
                                  border={'none'}
                                  color={'#212121'}
                                  fontWeight={'600'}
                                  width={'100%'}
                                >
                                  <Flex alignItems={'center'} justifyContent={'space-between'}>
                                    <Flex gap={1.5} alignItems={'center'}>
                                      <DimensionIconComponent
                                        height={5}
                                        width={5}
                                        color={'#4BB6B9'}
                                      />
                                      {item.dimensionName?.length > 10 ? (
                                        <Text>{item.dimensionName.slice(0, 10)}...</Text>
                                      ) : (
                                        <Text>{item.dimensionName || 'Dimension'}</Text>
                                      )}
                                    </Flex>
                                    {widgetType !== 2 && <GoChevronDown size={18} />}
                                  </Flex>
                                </MenuButton>
                                {isOpen && (
                                  <MenuList>
                                    <>
                                      {dimensions?.length === 1 ? (
                                        <MenuItem>No dimension</MenuItem>
                                      ) : (
                                        <>
                                          {selectedBlock === null ? (
                                            <MenuItem>Select a block</MenuItem>
                                          ) : (
                                            <>
                                              {myDim.map((dim: any) =>
                                                dim.dimension_name === 'Time' ? null : (
                                                  <MenuItem
                                                    onClick={() =>
                                                      handleDimensionSelect(dim, item.localId)
                                                    }
                                                    key={dim.dimension_id}
                                                  >
                                                    {dim.dimension_name}
                                                  </MenuItem>
                                                ),
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  </MenuList>
                                )}
                              </>
                            )}
                          </Menu>
                        </Box>
                      </Tooltip>
                      {widgetType !== 2 && (
                        <>
                          <Divider
                            mr={'.5rem'}
                            borderColor={'#706B9A'}
                            orientation='vertical'
                            height={'15px'}
                            border={'1px'}
                          />
                          <Tooltip
                            placement='top'
                            label={
                              item?.dimensionItems?.length === 1
                                ? item?.dimensionItems[0]?.dimension_item_name
                                : 'Items'
                            }
                          >
                            <Box width={'50%'}>
                              <Menu variant={'dashboardingModal'}>
                                {({ isOpen }) => (
                                  <>
                                    <MenuButton
                                      as={Button}
                                      padding={'.3rem .5rem'}
                                      variant={'dashboardingModal'}
                                      border={'none'}
                                      rightIcon={<GoChevronDown size={18} />}
                                    >
                                      {item?.dimensionItems?.length === 1 ? (
                                        <>
                                          {item?.dimensionItems[0]?.dimension_item_name?.length >
                                          12 ? (
                                            <Text>
                                              {item?.dimensionItems[0]?.dimension_item_name.slice(
                                                0,
                                                12,
                                              )}
                                              ...
                                            </Text>
                                          ) : (
                                            <Text>
                                              {item?.dimensionItems[0]?.dimension_item_name ||
                                                'Dimension'}
                                            </Text>
                                          )}
                                        </>
                                      ) : (
                                        <Text>Items</Text>
                                      )}
                                    </MenuButton>
                                    {renderMenuItems(isOpen, myDim, item)}
                                  </>
                                )}
                              </Menu>
                            </Box>
                          </Tooltip>
                        </>
                      )}
                      {widgetType === 2 && (
                        <Flex mr={1}>
                          <GoChevronDown size={18} />
                        </Flex>
                      )}
                    </Flex>
                    <RemoveIconComponent
                      color={'black.900'}
                      onClick={() => handleRemovingFilter(item.localId, item.dimensionName)}
                    />
                  </Flex>
                );
              })}
            </Flex>
          </Box>
        </>
      )}
    </Flex>
  );
};

export default DashboardModalFilters;
