import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import AuthProvider from 'context/authContext';
import { DriversAdjustmentProvider } from 'context/driversAdjustmentContext';
import 'matchmedia-polyfill';
import Navigation from 'navigation';
import AppWrapper from 'container/AppWrapper';
import { getUserData } from 'redux/UserSlice';

import CustomToastComponent from './CustomToast/toaster';
import SharedLoader from './FullScreenLoader/SharedLoader';
import Header from './Header';

const App = () => {
  const { pathname } = useLocation();
  const isLoginPage = pathname === '/';
  const isSplashPage = pathname.includes('/create');
  const isChangePasswordPage = pathname.includes('/change-password');
  const isSetPasswordPage = pathname.includes('/set-password');
  const isSetNewPasswordPage = pathname.includes('/set-new-password');
  const isWelcomePage = pathname.includes('/user/welcome');
  const isShareModelPage = pathname.includes('/share-model');
  const isSignupPage = pathname.includes('/signup');
  const isInvitationModal = pathname.includes('/invitation');
  const workspaceCode: any = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE;

  const ldClient = useLDClient();

  const user = useSelector(getUserData);

  const [showProductFruits, setShowProductFruits] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: user?.username,
    email: user?.username,
    firstname: user?.firstname,
    lastname: user?.lastname,
  });

  // Set user information and show ProductFruits
  useEffect(() => {
    if (user) {
      setUserInfo({
        username: user.username,
        email: user.username,
        firstname: user.firstname,
        lastname: user.lastname,
      });
      const ldUserContext = {
        kind: 'user',
        key: user?.id,
        name: user?.username,
        username: user?.username,
        email: user?.username,
        custom: {
          industry: user?.industry,
          organization: user?.organization,
          role: user?.role,
        },
      };
      ldClient?.identify(ldUserContext);
      setShowProductFruits(true);
    }
  }, [user]);

  return (
    <AuthProvider>
      <AppWrapper>
        <SharedLoader />
        <DriversAdjustmentProvider>
          {isLoginPage ||
          isSplashPage ||
          isChangePasswordPage ||
          isSetNewPasswordPage ||
          isSetPasswordPage ||
          isSignupPage ||
          isWelcomePage ||
          isInvitationModal ||
          isShareModelPage ? null : (
            <Header />
          )}
          <CustomToastComponent />
          {showProductFruits && (
            <ProductFruits workspaceCode={workspaceCode} language='en' user={userInfo} />
          )}
          <Navigation />
        </DriversAdjustmentProvider>
      </AppWrapper>
    </AuthProvider>
  );
};

export default App;
