import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';

import { getSelectedRow } from 'redux/DashboardSlice';

const CellColor = ({ updateMetric }: any) => {
  const selectedRow = useSelector(getSelectedRow);
  const [currentColor, setCurrentColor] = useState<any>('');

  const handleColorChange = (event: any) => {
    const color = event.target.value;
    setCurrentColor(color);
  };

  useEffect(() => {
    if (selectedRow) {
      setCurrentColor(selectedRow?.formatting?.cellColor);
    }
  }, [selectedRow]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!selectedRow && currentColor !== '') {
      updateMetric(null);
    } else if (currentColor !== '' && selectedRow) {
      const handler = setTimeout(() => {
        const indicatorPayload = JSON.parse(JSON.stringify(selectedRow));
        indicatorPayload.formatting.cellColor = currentColor;
        updateMetric(indicatorPayload);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [currentColor]);

  return (
    <Flex alignItems={'center'} mb={2} justifyContent={'space-between'}>
      <Text color={'black.900'} fontWeight={'600'}>
        Cell colour
      </Text>
      <Flex alignItems='center' justifyContent={'center'}>
        <input
          type='color'
          value={currentColor}
          onChange={handleColorChange}
          style={{
            width: '24px',
            height: '24px',
            border: '1px solid #D9D9D9',
            borderRadius: '2px',
            backgroundColor: 'none',
          }}
        />
        <ChevronDownIcon />
      </Flex>
    </Flex>
  );
};

export default CellColor;
