import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getSelectedRow } from 'redux/DashboardSlice';

const TextColor = ({ updateMetric }: any) => {
  const [currentColor, setCurrentColor] = useState<any>('');
  const selectedRow = useSelector(getSelectedRow);

  const handleColorChange = (event: any) => {
    const color = event.target.value;
    setCurrentColor(color);
  };

  useEffect(() => {
    if (selectedRow) {
      setCurrentColor(selectedRow?.formatting?.textColor);
    }
  }, [selectedRow]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!selectedRow && currentColor !== '') {
      updateMetric(null);
    } else if (currentColor !== '' && selectedRow) {
      const handler = setTimeout(() => {
        const indicatorPayload = JSON.parse(JSON.stringify(selectedRow));
        indicatorPayload.formatting.textColor = currentColor;
        updateMetric(indicatorPayload);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [currentColor]);

  return (
    <Flex alignItems={'center'} mb={2} justifyContent={'space-between'}>
      <Text color={'black.900'} fontWeight={'600'}>
        Text colour
      </Text>
      <Flex flexDirection={'column'} alignItems='center' justifyContent={'center'}>
        <Text>A</Text>
        <input
          value={currentColor}
          onChange={handleColorChange}
          type='color'
          style={{ width: '24px', height: '15px' }}
        />
      </Flex>
    </Flex>
  );
};

export default TextColor;
