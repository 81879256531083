/* eslint-disable array-callback-return */
import React from 'react';
import { Container, Flex, Box, IconButton, Text, Button } from '@chakra-ui/react';
import { connect } from 'react-redux';

import OrganisationIcon from 'components/Icons/OrganisationIcon';
import UserIcon from 'components/Icons/UserIcon';
import TeamIcon from 'components/Icons/TeamIcon';
import BillingIcon from 'components/Icons/BillingIcon';
import { SetTabs, UserItems } from 'redux/UserSlice';
import UserPermissions from 'utils/UserPermissions';
import { UserSubscriptionLimit } from 'models/UsersSlice.interface';

interface Properties {
  userTabs: number;
  userData: any;
  userSubscriptionLimits: UserSubscriptionLimit;
  setUserTabs: (parameters: number) => void;
  userManagementItems: UserItems[];
}

const UserSidebar = ({
  userTabs,
  userData,
  userSubscriptionLimits,
  setUserTabs,
  userManagementItems,
}: Properties) => {
  const getBgColor = (index: number) => {
    return userTabs === index ? '#DFE5F2' : 'white';
  };

  const getIcon = (index: number) => {
    return index === 0 ? (
      <UserIcon height={25} width={25} color={userTabs === index + 1 ? '#6564C8' : 'black'} />
    ) : index === 1 ? (
      <OrganisationIcon
        height={25}
        width={25}
        color={userTabs === index + 1 ? '#6564C8' : 'black'}
      />
    ) : index === 2 ? (
      <TeamIcon height={25} width={25} color={userTabs === index + 1 ? '#6564C8' : 'black'} />
    ) : (
      <BillingIcon height={25} width={25} color={userTabs === index + 1 ? '#6564C8' : 'black'} />
    );
  };

  return (
    <Container padding='0 1rem 0 0' marginTop={'1rem'}>
      {userManagementItems.map((data: UserItems, index: number) => {
        return (
          <React.Fragment key={index}>
            <UserPermissions scopes={data?.tabRoles} permissionType='user'>
              {!data.subMenu && (
                <Flex
                  cursor='pointer'
                  width='100%'
                  padding='.7rem 1rem'
                  borderRadius={'10px'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  backgroundColor={getBgColor(index + 1)}
                  onClick={() => {
                    setUserTabs(index + 1);
                  }}
                >
                  <Flex>
                    <IconButton
                      size='xs'
                      icon={getIcon(index)}
                      aria-label='users'
                      border={'none'}
                    />
                    <Text ml='1.3rem' color={userTabs === index + 1 ? '#6564C8' : 'black'}>
                      {data.name}
                    </Text>
                  </Flex>
                  {userData?.role !== 'planner' &&
                    userSubscriptionLimits.current_subscription_name === 'Free' &&
                    index === 2 && (
                      <Button
                        fontWeight={'100'}
                        padding={'.4rem 1.5rem'}
                        height={'fit-content'}
                        width={'fit-content'}
                        borderRadius={'10px'}
                        border={'none'}
                        backgroundColor='green.700'
                        onClick={(event) => {
                          event.stopPropagation();
                          setUserTabs(5);
                        }}
                      >
                        Upgrade
                      </Button>
                    )}
                </Flex>
              )}
            </UserPermissions>
          </React.Fragment>
        );
      })}
      {(userTabs === 4 || userTabs === 5 || userTabs === 6 || userTabs === 7) && (
        <Box>
          <Box
            borderRadius={'10px'}
            cursor={'pointer'}
            width='100%'
            // onClick={() => setUserTabs(5)}
            onClick={() => {
              window.open('https://www.blox.so/pricing', '_blank');
            }}
            backgroundColor={getBgColor(5)}
            padding={'.5rem'}
          >
            <Text ml='30%'>Plan</Text>
          </Box>
        </Box>
      )}
    </Container>
  );
};

const stateToProperty = (state: any) => {
  return {
    userData: state.userSlice.userData,
    userTabs: state.userSlice.userTabs,
    userManagementItems: state.userSlice.userManagementItems,
    userSubscriptionLimits: state.userSlice.userSubscriptionLimits,
  };
};

const mapDispatchToProperties = (dispatch: any) => {
  return {
    setUserTabs: (data: any) => dispatch(SetTabs(data)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(UserSidebar);
