import { useDispatch, useSelector } from 'react-redux';

import {
  getAllDashboardTableData,
  handleAddAllChartData,
  setDashboardTableFilter,
  fetchDashboardGraph,
  fetchDashboardChart,
  getAllChartData,
} from 'redux/DashboardSlice';
import TabsFilter from 'pages/PlanPageForecast/Plan/Filters/TabsFilter';
import formatIndicatorTableData from 'utils/FormatTable';
import { getAllScenarios } from 'redux/ScenarioSlice';

interface Properties {
  isDashboardLayout?: any;
  chartId?: any;
  chartData?: any;
  setTimeGranularityFilter?: any;
}

const TimeAggregationFilter = ({
  setTimeGranularityFilter,
  isDashboardLayout,
  chartId,
  chartData,
}: Properties) => {
  const dispatch: any = useDispatch();
  const allChartData = useSelector(getAllChartData);
  const allScenarios = useSelector(getAllScenarios);
  const scenarioId = allScenarios.find((item: any) => item.name === 'Baseline')?.id;

  const allTable = useSelector(getAllDashboardTableData);
  const handleChange = (value: string) => {
    if (isDashboardLayout) {
      const tableData = allTable[chartId];
      const layoutData = {
        id: tableData.id,
        x: Number(tableData.x),
        y: Number(tableData.y),
        h: tableData.h || 2,
        w: tableData.w || 2,
        instrument_type: 'table',
      };
      const formattedTableData = formatIndicatorTableData({
        tableFilter: value,
        indicatorTableData: tableData,
        expandKey: '',
      });
      dispatch(handleAddAllChartData({ ...formattedTableData, ...layoutData }));
    } else {
      dispatch(setDashboardTableFilter(value));
    }
    if (chartData?.name === 'chart') {
      setTimeGranularityFilter(value);
      dispatch(fetchDashboardGraph({ chartId: chartData.id, scenarioId, requestBody: [] })).then(
        (data: any) => {
          const updatedData = allChartData[chartData.id];
          const layoutData = {
            id: updatedData.id,
            x: Number(updatedData.x),
            y: Number(updatedData.y),
            h: updatedData.h || 2,
            w: updatedData.w || 2,
            instrument_type: updatedData.instrument_type,
          };
          dispatch(handleAddAllChartData({ ...data.payload, ...layoutData }));
          dispatch(fetchDashboardChart(chartData.id));
        },
      );
    }
  };

  return (
    <TabsFilter
      timeGranularity='M'
      onChangeSummeryMode={handleChange}
      fontColor={'#787878'}
      bg={'#F7F7F815'}
      color='white'
    />
  );
};
export default TimeAggregationFilter;
