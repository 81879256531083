import { Td, Tr } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ChevronDown from 'components/Icons/ChevronDown';
import ChevronRight from 'components/Icons/ChevronRight';
import formatValue from 'utils/FormatValues';
import { handleSelectedRow } from 'redux/DashboardSlice';

interface Properties {
  row: any;
  expandkey: string;
  isDashboardLayout?: boolean;
}
export interface NewTotalValues {
  Time: string;
  value: number | null;
  value2?: number | null;
  diff?: number | null;
  varience?: number | null;

  isShow?: boolean;
  isShowExpandable?: boolean;
  isExpandedColumn?: boolean;
  isActualValue?: boolean;
}

const DashboardTableRow = ({ row, expandkey, isDashboardLayout }: Properties) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const dispatch = useDispatch();

  const onToggleCollapse = () => {
    if (!isDashboardLayout) {
      dispatch(handleSelectedRow(row.indicator));
    }
    setIsCollapsed((previousState) => !previousState);
  };

  const getObjectValue = (object: any) => {
    return Object.values(object);
  };

  const subData: any = row.dim_df
    ? Object.entries(row.dim_df).map((entry: any) => ({
        item_name: getObjectValue(entry[1])[0],
        item_values: entry[1].values || [],
      }))
    : [
        {
          item_name: 'Total',
          item_values: row.total || [],
        },
      ];

  const isConditionalFormatting = (displayValue: any) => {
    switch (row.conditional_formatting?.value) {
      case 'is less than':
        return displayValue < Number(row.conditional_formatting?.formatting?.value);
      case 'is greater than':
        return displayValue > Number(row.conditional_formatting?.formatting?.value);
      case 'equal to':
        return displayValue === Number(row.conditional_formatting?.formatting?.value);
      default:
        return false;
    }
  };

  const getBackgroundStyle = (
    output_format: any,
    key: string,
    isExpandedColumn: boolean,
    isSubRow?: boolean,
    isActualValue?: boolean,
    value?: any,
  ) => {
    const subTotalIndicator = isSubRow ? '#EFFDFE' : '#BCEEF2';
    const regularIndicator = '#EFFDFE';
    const subTotalIndicatorTimeExpansion = isSubRow ? '#DEF2F3' : '#A7E6EB';
    const regularIndicatorTimeExpansion = '#DEF2F3';
    if (output_format) {
      return {
        textAlign: output_format?.textAlign,
        backgroundColor: output_format?.cellColor,
        color: isConditionalFormatting(value)
          ? row.conditional_formatting?.formatting?.color
          : output_format?.textColor,
        textDecoration: output_format?.textDecoration,
        fontStyle: output_format?.textDecoration,
        fontWeight: output_format?.textDecoration === 'bold' ? '700' : '400',
      };
    }

    if (isExpandedColumn) {
      return {
        background: isActualValue
          ? output_format === 'subtotal'
            ? subTotalIndicatorTimeExpansion
            : regularIndicatorTimeExpansion
          : '#E7EBEF',
      };
    }

    if (key === expandkey && isSubRow) {
      return {
        background: isActualValue
          ? output_format === 'subtotal'
            ? regularIndicatorTimeExpansion
            : regularIndicatorTimeExpansion
          : '#D1D9E0',
      };
    }

    if (key === expandkey && !isSubRow) {
      return {
        background: isActualValue
          ? output_format === 'subtotal'
            ? subTotalIndicatorTimeExpansion
            : regularIndicatorTimeExpansion
          : '#D1D9E0',
      };
    }

    const color = isActualValue ? subTotalIndicator : '#F4F7FF';

    return {
      background: output_format === 'subtotal' ? color : isActualValue ? regularIndicator : '#FFF',
    };
  };

  const format = {
    scale: null,
    measure: null,
    null_value: '',
    decimal_points: 2,
    thousand_separators: true,
    negative_parentheses: false,
  };

  return (
    <>
      <Tr
        // border={'1px solid red'}
        height={'8'}
        onClick={onToggleCollapse}
        fontWeight={row?.output_format === 'subtotal' ? 'semibold' : 'regular'}
      >
        <Td
          style={{
            ...getBackgroundStyle(row.indicator?.formatting, '-', false, false, true),
            cursor: row.dim_df ? 'pointer' : 'default',
            paddingLeft: '1rem',
            verticalAlign: 'middle',
          }}
          fontSize={'2xs'}
          data-testid='table-header'
          borderBottom={isCollapsed ? '1px solid #D9D9D9' : ''}
          // border={'1px solid red'}
        >
          {row.dim_df ? isCollapsed ? <ChevronRight /> : <ChevronDown /> : <></>}
          {row.indicator?.indicator_name}
        </Td>
        {Object.values(row.total as unknown as NewTotalValues[]).map((item: any, index) => {
          if (item.isShow) {
            if (item.value === null) {
              return (
                <Td
                  key={item.Time + index}
                  style={getBackgroundStyle(
                    row.indicator?.formatting,
                    item.Time,
                    Boolean(item?.isExpandedColumn),
                    false,
                    Boolean(item?.isActualValue),
                  )}
                  fontSize={'2xs'}
                >
                  {format.null_value}
                </Td>
              );
            }
            return (
              <Td
                key={item.Time + index}
                paddingRight='1rem'
                style={getBackgroundStyle(
                  row.indicator?.formatting,
                  item.Time,
                  Boolean(item?.isExpandedColumn),
                  false,
                  Boolean(item?.isActualValue),
                  getObjectValue(item)[1],
                )}
                verticalAlign='middle !important'
                textAlign={'start'}
                fontSize={'2xs'}
                borderBottom={isCollapsed ? '1px solid #D9D9D9' : ''}
                // border={'1px solid red'}
              >
                {formatValue(getObjectValue(item)[1] || 0, format)}
              </Td>
            );
          }
          return <React.Fragment key={item.Time + index}></React.Fragment>;
        })}
      </Tr>

      {!isCollapsed &&
        row.dim_df &&
        subData?.map(
          (subRow: any, index: any) =>
            subRow.item_values &&
            subRow.item_values.length > 0 && (
              <Tr
                height={'9'}
                key={index}
                fontWeight={row?.output_format === 'subtotal' ? 'semibold' : 'regular'}
              >
                <Td
                  // style={getBackgroundStyle(row.indicator?.formatting, '-', false, true, true)}
                  pl='5'
                  color='grey.900'
                  fontSize={'2xs'}
                  borderBottom={index === subData.length - 1 ? '1px solid #D9D9D9' : ''}
                >
                  {subRow.item_name}
                </Td>
                {(subRow.item_values as NewTotalValues[]).map(
                  (itemValue: any, itemValueIndex: number) => {
                    if (itemValue.isShow) {
                      if (itemValue === null) {
                        return (
                          <Td
                            key={itemValue.Time + itemValueIndex}
                            style={getBackgroundStyle(
                              row.output_format,
                              itemValue.Time,
                              itemValue.isExpandedColumn,
                              true,
                              Boolean(itemValue?.isActualValue),
                              getObjectValue(itemValue)[1],
                            )}
                            color='grey.900'
                            fontSize={'2xs'}
                            textAlign={'start'}
                            borderBottom={index === subData.length ? '1px solid #D9D9D9' : ''}
                          >
                            {format.null_value}
                          </Td>
                        );
                      }
                      return (
                        <Td
                          key={itemValue.Time + index}
                          // style={getBackgroundStyle(
                          //   row.output_format,
                          //   itemValue.Time,
                          //   itemValue.isExpandedColumn,
                          //   true,
                          //   Boolean(itemValue?.isActualValue),
                          // )}
                          color='grey.900'
                          fontSize={'2xs'}
                          textAlign={'start'}
                          borderBottom={index === subData.length - 1 ? '1px solid #D9D9D9' : ''}
                        >
                          {formatValue(getObjectValue(itemValue)[1] || 0, format)}
                        </Td>
                      );
                    }
                    return <React.Fragment key={itemValue.Time + itemValueIndex}></React.Fragment>;
                  },
                )}
              </Tr>
            ),
        )}
    </>
  );
};

export default DashboardTableRow;
