/* eslint-disable consistent-return */
import {
  Text,
  Flex,
  Button,
  MenuItem,
  MenuList,
  Box,
  Tooltip,
  MenuButton,
  Menu,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Info from 'components/Icons/Info';
import MultiColorIcon from 'components/Icons/MultiColorIcon';
import {
  getBlock,
  handleDimItemColor,
  getDimensions,
  fetchDashboardGraph,
  getTableIndicator,
  updateChartMetric,
  getIsIndicatorDimension,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import Plus from 'components/Icons/Plus';
import RemoveIconComponent from 'components/Icons/RemoveIcon';
import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';

interface Properties {
  chartData?: any;
  dimensionData?: any;
  isEditMode?: any;
}

const Dimension = ({ chartData, dimensionData }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const selectedBlock = useSelector(getBlock);
  const dimensions = useSelector(getDimensions);
  const isIndicatorDimension = useSelector(getIsIndicatorDimension);
  const pieGraphData = useSelector(getTableIndicator);

  const colorInputReference = useRef<HTMLInputElement>(null);
  const [myDim, setDims] = useState(dimensions);
  const [dimensionName, setDimensionName] = useState('');
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const [selectedDimFilter, setSelectedDimFilter] = useState<any[]>([]);

  const matrixData = useMemo(() => {
    if (chartData) {
      const matrix = chartData?.instrument_indicators[0];
      return matrix;
    }
  }, [chartData]);

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let index = 0; index < 6; index += 1) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useMemo(() => {
    const apiDimensionData = pieGraphData?.data?.find((item: any) => item.dim_df !== null);
    const dimName = apiDimensionData?.metric?.dimension_name;
    const apiDimensionId = myDim?.find(
      (item: any) => item.dimension_name === dimName,
    )?.dimension_id;
    if (apiDimensionData) {
      const transformedArray = [
        {
          localId: pieGraphData?.data?.length,
          id: apiDimensionData?.metric?.id,
          dimensionId: apiDimensionId,
          dimensionItems: apiDimensionData?.metric?.formatting?.dimensionItems?.map((item: any) => {
            return {
              dimension_item_id: item.dimension_item_id,
              dimension_item_name: item.dimension_item_name,
              color: item.color,
            };
          }),
          dimensionName: apiDimensionData?.metric?.dimension_name,
        },
      ];
      setSelectedDimFilter(transformedArray);
    }
  }, [pieGraphData]);

  useMemo(() => {
    if (dimensionData) {
      const dimName = dimensionData?.data?.find((item: any) => item.dim_df !== null)?.metric
        ?.dimension_name;
      setDimensionName(dimName);
    }
  }, [dimensionData]);

  const handleAddDimension = () => {
    setSelectedDimFilter([
      ...selectedDimFilter,
      {
        localId: selectedDimFilter.length + 1,
        id: '',
        dimensionId: 0,
        dimensionItems: [],
        dimensionName: 'Dimension',
      },
    ]);
  };

  const handleRemoveDimnension = (itemId: any) => {
    const newDimensionsFilters = selectedDimFilter.filter(
      (filter: any) => filter.localId !== itemId,
    );
    const pieMatrixObject = {
      indicator_name: matrixData.indicator_name,
      instrument_id: matrixData?.instrument_id,
      block_id: matrixData?.block_id,
      id: matrixData.id,
      dimension_name: null,
      formatting: {},
      aggregate_function: 'sum',
    };
    dispatch(updateChartMetric(pieMatrixObject)).then(() => {
      dispatch(
        fetchDashboardGraph({
          chartId: chartData?.id,
          scenarioId,
          requestBody: [],
        }),
      );
    });
    setSelectedDimFilter(newDimensionsFilters);
    dispatch(handleDimItemColor(newDimensionsFilters));
  };

  const handleDimensionSelect = (dim: any, localId: any) => {
    setDimensionName(dim.dimension_name);
    const dimensionItems = myDim
      ?.filter((item: any) => item.dimension_id === dim.dimension_id)
      ?.map((item: any) => item.dimension_items);

    const formateDimensionItems = dimensionItems.map((items: any) =>
      items.map((item: any) => ({
        ...item,
        color: getRandomColor(),
      })),
    );
    const newDimensionsFilters = selectedDimFilter.map((filter: any) =>
      filter.localId === localId
        ? {
            ...filter,
            dimensionName: dim.dimension_name,
            dimensionId: dim.dimension_id,
            dimensionItems: formateDimensionItems[0],
          }
        : filter,
    );
    setSelectedDimFilter(newDimensionsFilters);
    dispatch(handleDimItemColor(newDimensionsFilters));
    const pieMatrixObject = {
      indicator_name: matrixData.indicator_name,
      instrument_id: matrixData?.instrument_id,
      block_id: matrixData?.block_id,
      id: matrixData.id,
      dimension_name: newDimensionsFilters[0].dimensionName,
      formatting: { dimensionItems: newDimensionsFilters[0].dimensionItems },
      aggregate_function: 'sum',
    };
    dispatch(updateChartMetric(pieMatrixObject)).then(() => {
      dispatch(
        fetchDashboardGraph({
          chartId: chartData?.id,
          scenarioId,
          requestBody: [],
        }),
      );
    });
  };

  const handleColorIconClick = () => {
    if (colorInputReference.current) {
      colorInputReference.current.click();
    }
  };

  const handleColorChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    items: any,
    local_id: any,
  ) => {
    const itemColor = event.target.value;
    const newDimensionsFilters = selectedDimFilter.map((item: any) =>
      item.localId === local_id
        ? {
            ...item,
            dimensionItems: item.dimensionItems.map((dimItem: any) =>
              dimItem.dimension_item_id === items.dimension_item_id
                ? {
                    ...dimItem,
                    color: itemColor,
                  }
                : dimItem,
            ),
          }
        : item,
    );
    const pieMatrixObject = {
      indicator_name: matrixData.indicator_name,
      instrument_id: matrixData?.instrument_id,
      block_id: matrixData?.block_id,
      id: matrixData.id,
      dimension_name: newDimensionsFilters[0].dimensionName,
      formatting: { dimensionItems: newDimensionsFilters[0].dimensionItems },
      aggregate_function: 'sum',
    };
    dispatch(updateChartMetric(pieMatrixObject)).then(() => {
      dispatch(
        fetchDashboardGraph({
          chartId: chartData?.id,
          scenarioId,
          requestBody: [],
        }),
      );
    });
    setSelectedDimFilter(newDimensionsFilters);
    dispatch(handleDimItemColor(newDimensionsFilters));
  };
  useEffect(() => {
    if (dimensions) setDims(dimensions);
  }, [dimensions]);

  const renderMenuItems = (isOpen: any, items: any) => {
    if (!isOpen) return null;

    return (
      <MenuList
        sx={{
          padding: '5px',
          borderRadius: '9px',
          border: '0.719px solid #F5F5F5',
          boxShadow: '0px 0px 18.977px 0px',
        }}
      >
        {items?.dimensionId === 0 ? (
          <MenuItem>Select Dimension</MenuItem>
        ) : (
          items?.dimensionItems?.map((item: any) => (
            <Flex
              key={item?.dimension_item_id}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Tooltip placement='top' label={item?.dimension_item_name}>
                <Flex w={'75%'} mb={1}>
                  {item?.dimension_item_name?.length > 10 ? (
                    <Text>{item?.dimension_item_name.slice(0, 10)}...</Text>
                  ) : (
                    <Text>{item?.dimension_item_name}</Text>
                  )}
                </Flex>
              </Tooltip>
              <Flex alignItems={'center'} w={'25%'}>
                <Flex w={'70%'}>
                  <input
                    type='color'
                    value={item?.color}
                    onChange={(event) => handleColorChange(event, item, items?.localId)}
                    style={{
                      width: '22px',
                      height: '22px',
                    }}
                  />
                </Flex>
                <Flex w={'30%'}>
                  <ChevronDownIcon
                    onClick={handleColorIconClick}
                    color={'black'}
                    style={{ cursor: 'pointer' }}
                  />
                </Flex>
              </Flex>
            </Flex>
          ))
        )}
      </MenuList>
    );
  };

  return (
    <>
      {isIndicatorDimension ? (
        <>
          <Flex flexDir={'column'}>
            <Flex alignItems={'center'} justifyContent={'space-between'} mb={2}>
              <Text color={'black.900'} fontWeight={'600'}>
                Dimensions
              </Text>
              <Button
                width={'14.583px'}
                //  disabled={selectedDimFilter??.length > dimentionsFilters??.length}
              >
                <Plus color='#787878' onClick={() => handleAddDimension()} />
              </Button>
            </Flex>
            {selectedDimFilter &&
              selectedDimFilter.map((item: any) => {
                return (
                  <>
                    <Flex
                      key={item.localId}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      mb={2}
                    >
                      <Flex
                        width={'90%'}
                        backgroundColor={'#F5F5F5'}
                        borderRadius={'6px'}
                        p={'4px 3px'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Menu>
                          {({ isOpen }) => (
                            <>
                              <MenuButton>
                                <Flex alignItems={'end'}>
                                  <DimensionTabIcon width={5} height={5} />
                                  <Text color={'#212121'} fontSize={'11.74px'} fontWeight={'400'}>
                                    {dimensionName || 'Dim'}
                                  </Text>
                                </Flex>
                              </MenuButton>
                              {isOpen && (
                                <MenuList>
                                  <>
                                    {dimensions?.length === 1 ? (
                                      <MenuItem>No dimension</MenuItem>
                                    ) : (
                                      <>
                                        {selectedBlock === null ? (
                                          <MenuItem>Select a block</MenuItem>
                                        ) : (
                                          <>
                                            {myDim.map((dim: any) =>
                                              dim.dimension_name === 'Time' ? null : (
                                                <MenuItem
                                                  onClick={() =>
                                                    handleDimensionSelect(dim, item.localId)
                                                  }
                                                  key={dim.dimension_id}
                                                >
                                                  {dim.dimension_name}
                                                </MenuItem>
                                              ),
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                </MenuList>
                              )}
                            </>
                          )}
                        </Menu>
                        <Flex>
                          <Menu>
                            {({ isOpen }) => (
                              <>
                                <MenuButton width={'60px'} m={0} p={0} as={Button} border={'none'}>
                                  <Flex justifyContent={'flex-start'} alignItems={'center'}>
                                    <Box>
                                      <MultiColorIcon />
                                    </Box>
                                    <Box>
                                      <ChevronDownIcon
                                        style={{ cursor: 'pointer' }}
                                        color={'black'}
                                        height={'16px'}
                                        width={'16px'}
                                      />
                                    </Box>
                                  </Flex>
                                </MenuButton>
                                {renderMenuItems(isOpen, item)}
                              </>
                            )}
                          </Menu>
                        </Flex>
                      </Flex>
                      <Flex alignItems={'flex-end'} width={'8%'}>
                        <RemoveIconComponent
                          color={'black.900'}
                          onClick={() => handleRemoveDimnension(item.localId)}
                        />
                      </Flex>
                    </Flex>
                  </>
                );
              })}
          </Flex>
        </>
      ) : (
        <>
          <Flex
            backgroundColor='rgba(255, 255, 255, 0.10)'
            borderRadius={'4px'}
            p={1}
            border={'0.5px solid #D9D9D9'}
            gap={2}
            alignItems={'center'}
          >
            <Info height={6} width={6} color='#D9D9D9' />
            <Text fontSize={'0.8rem'} color={'#D9D9D9'}>
              No dimensions available for the chosen indicator
            </Text>
          </Flex>
        </>
      )}
    </>
  );
};

export default Dimension;
