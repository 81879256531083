/* eslint-disable no-restricted-syntax */
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { STATUS } from 'projectConstants';
import apiService from 'services';
import convertToDate from 'utils/getMonthYear';

export const FetchPlannerModeModel = createAsyncThunk(
  'plannerModeModel/get',
  async (modelId: number, thunkAPI) => {
    try {
      const response = await apiService.get_model(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchFormulaList = createAsyncThunk(
  'formulaList/get',
  async (modelId: any, thunkAPI) => {
    try {
      const response = await apiService.get_model_formula_list(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchDataInput = createAsyncThunk(
  'FetchDataInput/get',
  async ({ indicator_id, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_indicator_data_input(indicator_id, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchPlannerModeBlock = createAsyncThunk(
  'plannerModeBlock/get',
  async (blockId: number, thunkAPI) => {
    try {
      const response = await apiService.get_block(blockId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchPlannerModeItems = createAsyncThunk(
  'plannerModeItems/fetch',
  async ({ dimensionId, params, actuals }: any, thunkAPI) => {
    try {
      const response = await apiService.get_dimension_items(dimensionId, { params });
      return { ...response.data, actuals };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const FetchBlockOutputs = createAsyncThunk(
  'blockOutputs/fetch',
  async ({ blockId, params }: any, thunkAPI) => {
    try {
      const response = await apiService.get_block_outputs(blockId, { params });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const FetchBlockDataValues = createAsyncThunk(
  'blockDataValues/fetch',
  async ({ blockId, params }: any, thunkAPI) => {
    try {
      const response = await apiService.get_block_data_values(blockId, { params });
      return response.data?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const UpdateIndicator = createAsyncThunk(
  'indicator/patch',
  async (payload: any, thunkAPI) => {
    try {
      const response = await apiService.update_indicator(payload?.indicatorId, payload?.data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  },
);

export const UpdateIndicatorFormat = createAsyncThunk(
  'indicatorFormat/patch',
  async (payload: any, thunkAPI) => {
    try {
      const response = await apiService.update_indicator(payload?.indicatorId, payload?.data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  },
);

export const UpdateIndicatorProperties = createAsyncThunk(
  'indicatorProperties/patch',
  async ({ indicatorId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.update_indicator(indicatorId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  },
);

export const UpdateIndicatorFormula = createAsyncThunk(
  'indicatorFormula/patch',
  async ({ indicatorId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.update_indicator(indicatorId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  },
);

export const DuplicateIndicator = createAsyncThunk(
  'duplicateIndicator/post',
  async ({ indicatorId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.duplicate_indicator(indicatorId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  },
);

export const UpdateBlockDimension = createAsyncThunk(
  'blockDimension/post',
  async ({ blockId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.block_dimension(blockId, request_body);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const FetchScenarios = createAsyncThunk(
  'fetchScenarios/fetch',
  async (modelId: any, thunkAPI) => {
    try {
      const response = await apiService.get_scenarios(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const RenameScenario = createAsyncThunk(
  'scenarioRename/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.renameScenario(data?.item_id, data.requestBody);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DeleteScenarios = createAsyncThunk(
  'scenarioDelete/fetch',
  async (scenarioId: number, thunkAPI) => {
    try {
      const response = await apiService.deleteScenario(scenarioId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const CreateScenarios = createAsyncThunk(
  'scenarioCreate/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.createScenario(data?.modelId, data.data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const DuplicateScenarios = createAsyncThunk(
  'scenarioDuplicate/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.createDuplicateScenario(data?.scenarioId, data.data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const ReorderIndicator = createAsyncThunk(
  'reorderIndicator/fetch',
  async ({ blockId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.reorder_indicator(blockId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const FetchSingleScenarios = createAsyncThunk(
  'singleScenario/fetch',
  async (scenarioId: number, thunkAPI) => {
    try {
      const response = await apiService.getSingleScenario(scenarioId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const AddIndicator = createAsyncThunk('addIndicator/post', async (data: any, thunkAPI) => {
  try {
    const response = await apiService.add_indicator(data?.blockId, data.data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const DeleteIndicator = createAsyncThunk(
  'indicator/delete',
  async ({ indicatorId }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_Indicator(indicatorId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

interface PlannerModeSliceInterface {
  plannerModeSlice: PlannerModeInterface;
}

interface PlannerModeInterface {
  plannerMode: boolean;
  model: any;
  block: any;
  indicatorHeader: any;
  dimensionalIndicators: any;
  indicatorsData: any;
  selectedCell: any;
  addIndicator: boolean;
  selectedIndicators: any;
  toggleInputPanel: boolean;
  toolbarType: string;
  indicators: any[];
  scenarioFetchStatus: any;
  blockScenarioMap: any;
  modelId: any;
  blockId: null;
  baseScenario: any;
  scenarioList: any;
  scenarioListStatus: any;
  selectedSceanrioId: any;
  isIndicatorAvailabe: boolean;
  formulaBarChange: any;
  formulaList: any;
  scenarios: any[];
  dataInput: null;
  isExpand: boolean;
}

const initialState: PlannerModeInterface = {
  plannerMode: localStorage.getItem('BUILDER_MODE') === 'true',
  model: null,
  block: null,
  indicatorHeader: null,
  dimensionalIndicators: null,
  indicatorsData: null,
  selectedCell: null,
  selectedIndicators: null,
  addIndicator: false,
  toggleInputPanel: false,
  toolbarType: 'Data',
  indicators: [],
  modelId: null,
  blockId: null,
  scenarioFetchStatus: STATUS.IDLE,
  blockScenarioMap: {},
  baseScenario: null,
  scenarioList: null,
  scenarioListStatus: null,
  selectedSceanrioId: null,
  isIndicatorAvailabe: false,
  formulaBarChange: '',
  scenarios: [],
  formulaList: null,
  dataInput: null,
  isExpand: false,
};

const PlannerModeSlice = createSlice({
  name: 'plannerModeSlice',
  initialState,

  reducers: {
    handlePlannerMode: (state, action) => {
      localStorage.setItem('BUILDER_MODE', action.payload);
      state.plannerMode = action.payload;
    },
    handleToggleInputPanel: (state) => {
      state.toggleInputPanel = !state.toggleInputPanel;
    },
    toggleToolbarType: (state, action) => {
      state.toolbarType = action.payload;
    },
    handleAddIndicator: (state, action) => {
      state.addIndicator = action.payload;
    },
    handleSelectedCell: (state, action) => {
      state.selectedCell = action.payload;
      const indicators = current(state.indicators) || [];
      const getSelectedIndicators = indicators?.find((data: any) => {
        // For chevron cell
        if (typeof action.payload?.rowId === 'string') {
          const parts = action.payload?.rowId.split('_');
          return data?.id === Number(parts[1]);
        }
        return data?.id === action.payload?.rowId;
      });
      state.selectedIndicators = getSelectedIndicators;
    },
    currentScenario: (state, action) => {
      state.selectedSceanrioId = action.payload;
    },
    indicatorAvailable: (state, action) => {
      state.isIndicatorAvailabe = action.payload;
    },
    selectBaseScenario: (state, action) => {
      state.baseScenario = action.payload;
      state.blockScenarioMap[`${state.modelId}`] = state.baseScenario?.id;
    },
    setExpand: (state, action) => {
      state.isExpand = action.payload;
    },
    resetBuilderDimension: (state) => {
      state.model = null;
      state.block = null;
    },
    ResetTable: (state) => {
      state.model = null;
      state.block = null;
      state.scenarios = [];
      state.indicators = [];
      state.selectedCell = null;
      // state.baseScenario = null;
      state.scenarioList = null;
      state.toolbarType = 'Data';
      state.addIndicator = false;
      state.indicatorsData = null;
      state.formulaBarChange = '';
      state.indicatorHeader = null;
      state.toggleInputPanel = false;
      state.scenarioListStatus = null;
      state.selectedSceanrioId = null;
      state.selectedIndicators = null;
      state.selectedSceanrioId = null;
      state.dimensionalIndicators = null;
      state.isIndicatorAvailabe = false;
      state.formulaList = null;
      state.dataInput = null;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(FetchPlannerModeModel.fulfilled, (state, action) => {
        state.model = action.payload;
      })
      .addCase(FetchDataInput.fulfilled, (state, action) => {
        state.dataInput = action?.payload;
      })
      .addCase(FetchScenarios.fulfilled, (state, action) => {
        state.scenarios = action.payload.scenarios;
        state.scenarioFetchStatus = STATUS.SUCCESS;
        const userSpecificScenario = state.blockScenarioMap[`${state?.modelId}`];
        if (userSpecificScenario && state?.blockId) {
          state.baseScenario =
            state.scenarios &&
            state.scenarios.find((scenario: any) => {
              return scenario?.id === userSpecificScenario;
            });
        } else if (!state.baseScenario) {
          state.baseScenario =
            state.scenarios &&
            state.scenarios.find((scenario: any) => {
              return scenario.is_base;
            });
          if (state?.modelId) state.blockScenarioMap[`${state.modelId}`] = state.baseScenario?.id;
        }
      })
      .addCase(FetchScenarios.rejected, (state) => {
        state.scenarios = [];
      })
      .addCase(UpdateBlockDimension.fulfilled, (state, action) => {
        const block = current(state?.block) || [];
        const updatedDimension = { ...block, dimensions: action.payload?.dimensions };
        state.block = updatedDimension;
      })
      .addCase(FetchPlannerModeBlock.fulfilled, (state, action) => {
        state.block = action.payload;
      })
      .addCase(FetchPlannerModeItems.fulfilled, (state, action) => {
        const actualsData = action.payload?.items?.map((item: any) => {
          let isActual = false;
          if (
            action.payload.actuals[0] + action.payload.actuals[1] === 'FY' &&
            Number(action.payload.actuals[2] + action.payload.actuals[3]) + 2000 >=
              Number(item?.name)
          ) {
            isActual = true;
          } else if (convertToDate(action.payload?.actuals) >= convertToDate(item?.name)) {
            isActual = true;
          }
          return { ...item, isActual };
        });
        state.indicatorHeader = actualsData;
      })
      .addCase(UpdateIndicator.fulfilled, (state, action) => {
        // Update indicator for output indicator list
        const { payload } = action;
        const indicators = current(state.indicators) || [];
        const index = indicators.findIndex((data: any) => data?.id === payload?.id);
        if (index !== -1) {
          const newIndicator = {
            ...state.indicators[index],
            chart_type: payload?.chart_type,
            data_type: payload?.data_type,
            format: payload?.data_format,
            formula: payload?.formula,
            help: payload?.help,
            indicator_type: payload?.type,
            is_favourite: payload?.favourite,
            name: payload?.name,
            output_format: payload?.output_format,
            type: payload?.data_format,
          };
          state.indicators[index] = newIndicator;
          state.selectedIndicators = newIndicator;
        }

        // Updates Data view for table
        const indicatorsData = current(state.indicatorsData) || [];
        const indicatorIndex = indicatorsData.findIndex((data: any) => data?.id === payload?.id);
        if (indicatorIndex !== -1) {
          const indicatorPayload = {
            id: payload?.id,
            warning: payload?.warning,
            type: indicatorsData[indicatorIndex]?.type,
            'Block indicators': payload?.name,
            Type: payload?.type,
            Formula: payload?.formula ?? '',
            'Help text': payload?.help ?? '',
            Format: payload?.data_format,
            indicator_type: payload?.type,
            'Roll up type': payload?.data_type,
            'Display as output': payload?.favourite,
            'Display as sub-total': payload?.output_format,
            'Default chart type': payload?.chart_type,
          };
          state.indicatorsData[indicatorIndex] = indicatorPayload;
        }
        const blockOutputs: any = current(state.indicators) || [];
        const flattenedArray = [];
        for (const object of blockOutputs) {
          if (object?.dim_item_data) {
            flattenedArray.push({ ...object, chevronType: 'parent' });
          } else {
            flattenedArray.push(object);
          }
          if (object?.dim_item_data) {
            for (const dataObject of object.dim_item_data) {
              const childObject = {
                ...dataObject,
                type: object?.type,
                chevronType: 'child',
                total_values: dataObject?.value,
                indicatorId: object?.id,
                parentId: object?.id,
              };
              flattenedArray.push(childObject);
            }
          }
        }
        state.dimensionalIndicators = flattenedArray;
      })
      .addCase(UpdateIndicatorFormat.fulfilled, (state, action) => {
        // Update indicator for output indicator list
        const { payload } = action;
        const indicators = current(state.indicators) || [];
        const index = indicators.findIndex((data: any) => data?.id === payload?.id);
        if (index !== -1) {
          const newIndicator = {
            ...state.indicators[index],
            chart_type: payload?.chart_type,
            data_type: payload?.data_type,
            format: payload?.data_format,
            formula: payload?.formula,
            help: payload?.help,
            indicator_type: payload?.type,
            is_favourite: payload?.favourite,
            name: payload?.name,
            output_format: payload?.output_format,
            type: payload?.data_format,
          };
          state.indicators[index] = newIndicator;
          state.selectedIndicators = newIndicator;
        }
        // Updates Data view for table
        // const indicatorsData = current(state.indicatorsData) || [];
        // const indicatorIndex = indicatorsData.findIndex((data: any) => data?.id === payload?.id);
        // if (indicatorIndex !== -1) {
        //   const indicatorPayload = {
        //     id: payload?.id,
        //     warning: payload?.warning,
        //     type: indicatorsData[indicatorIndex]?.type,
        //     'Block indicators': payload?.name,
        //     Type: payload?.type,
        //     Formula: payload?.formula ?? '',
        //     'Help text': payload?.help ?? '',
        //     Format: payload?.data_format,
        //     indicator_type: payload?.type,
        //     'Roll up type': payload?.data_type,
        //     'Display as output': payload?.favourite,
        //     'Display as sub-total': payload?.output_format,
        //     'Default chart type': payload?.instrument_type,
        //   };
        //   state.indicatorsData[indicatorIndex] = indicatorPayload;
        // }
        // const blockOutputs: any = current(state.indicators) || [];
        // const flattenedArray = [];
        // for (const object of blockOutputs) {
        //   if (object?.dim_item_data) {
        //     flattenedArray.push({ ...object, chevronType: 'parent' });
        //   } else {
        //     flattenedArray.push(object);
        //   }
        //   if (object?.dim_item_data) {
        //     for (const dataObject of object.dim_item_data) {
        //       const childObject = {
        //         ...dataObject,
        //         type: object?.type,
        //         chevronType: 'child',
        //         total_values: dataObject?.value,
        //         indicatorId: object?.id,
        //         parentId: object?.id,
        //       };
        //       flattenedArray.push(childObject);
        //     }
        //   }
        // }
        // state.dimensionalIndicators = flattenedArray;
      })
      .addCase(FetchBlockOutputs.fulfilled, (state, action) => {
        state.indicators = action.payload;
        const indicatorsArray: any[] = [];
        action.payload.forEach((data: any) => {
          const indicator = {
            id: data?.id,
            warning: data?.warning,
            type: data?.type,
            'Block indicators': data?.name,
            Type: data?.indicator_type,
            Formula: data?.formula ?? '',
            indicator_type: data?.indicator_type,
            'Help text': data?.help ?? '',
            Format: data?.format,
            'Roll up type': data?.data_type,
            'Display as output': data?.is_favourite,
            'Display as sub-total': data?.output_format,
            'Default chart type': data?.chart_type,
          };
          indicatorsArray.push(indicator);
        });
        state.indicatorsData = indicatorsArray;

        const blockOutputs: any = action.payload;
        const flattenedArray = [];
        for (const object of blockOutputs) {
          if (object?.dim_item_data) {
            flattenedArray.push({ ...object, chevronType: 'parent' });
          } else {
            flattenedArray.push(object);
          }
          if (object?.dim_item_data) {
            for (const dataObject of object.dim_item_data) {
              const childObject = {
                ...dataObject,
                type: object?.type,
                chevronType: 'child',
                total_values: dataObject?.value,
                indicatorId: object?.id,
                parentId: object?.id,
              };
              flattenedArray.push(childObject);
            }
          }
        }
        state.dimensionalIndicators = flattenedArray;
        if (state.selectedIndicators) {
          const selectedIndicatorIndex = state.dimensionalIndicators.findIndex(
            (data: any) => data?.id === state.selectedIndicators?.id,
          );
          if (selectedIndicatorIndex !== -1) {
            // state.selectedIndicators = state.dimensionalIndicators[selectedIndicatorIndex];
          }
        }
      })
      .addCase(FetchFormulaList.fulfilled, (state, action) => {
        state.formulaList = action.payload;
      });
  },
});

export const getPlannerMode = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.plannerMode;
export const getPlannerModel = (state: PlannerModeSliceInterface) => state.plannerModeSlice.model;
export const getPlannerBlock = (state: PlannerModeSliceInterface) => state.plannerModeSlice.block;
export const getIndicatorHeaders = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.indicatorHeader;
export const getDimensionalIndicator = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.dimensionalIndicators;
export const getIndicatorsData = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.indicatorsData;
export const getIndicators = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.indicators;
export const getSelectedCell = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.selectedCell;
export const getSelectedIndicators = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.selectedIndicators;
export const getAddIndicator = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.addIndicator;
export const getToggleInputPanel = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.toggleInputPanel;
export const getToolbarType = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice.toolbarType;
export const getAllScenarios = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice?.scenarios;
export const getBaseScenario = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice?.baseScenario;
export const getFormulaBarStatus = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice?.formulaBarChange;
export const getDataInput = (state: PlannerModeSliceInterface) => state.plannerModeSlice?.dataInput;
export const getFormulaList = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice?.formulaList;
export const getExpandValue = (state: PlannerModeSliceInterface) =>
  state.plannerModeSlice?.isExpand;

export const {
  ResetTable,
  toggleToolbarType,
  handlePlannerMode,
  handleSelectedCell,
  handleAddIndicator,
  selectBaseScenario,
  handleToggleInputPanel,
  setExpand,
} = PlannerModeSlice.actions;

export default PlannerModeSlice.reducer;
