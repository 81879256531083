import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';

import { getIsEditModal, getKpiChartData } from 'redux/DashboardSlice';

import DashboardWidgetSetup from './Setup';

const DashboardModalTabs = () => {
  const chartData = useSelector(getKpiChartData);
  const isEditable = useSelector(getIsEditModal);

  return (
    <Box py={2} px={4}>
      <DashboardWidgetSetup
        updateChart={chartData !== null && chartData}
        isEditableModal={isEditable}
      />
    </Box>
  );

  // return (
  //   <Tabs variant={'dashboarding'} height={`calc(100% - 15px)`} p={'0 0 0 1rem'}>
  //     <TabList>
  //       <Tab>
  //         <Text color={'black.900'} size={'Roboto-16'} fontWeight={'600'}>
  //           Setup
  //         </Text>
  //       </Tab>
  //       <Tab>
  //         <Text color={'black.900'} size={'Roboto-16'} fontWeight={'600'}>
  //           Display
  //         </Text>
  //       </Tab>
  //       {widgetType === 3 && (
  //         <Tab>
  //           <Text color={'black.900'} size={'Roboto-16'} fontWeight={'600'}>
  //             Format
  //           </Text>
  //         </Tab>
  //       )}
  //     </TabList>
  //     <TabIndicator />
  //     <TabPanels height={`calc(100% - 40px)`} overflow={'auto'} className={'scroll'} pr={'1rem'}>
  //       <TabPanel display={'flex'} flexDir={'column'} gap={4}>
  //         <DashboardWidgetSetup
  //           updateChart={chartData !== null && chartData}
  //           isEditableModal={isEditable}
  //         />
  //       </TabPanel>
  //       <TabPanel display={'flex'} flexDir={'column'} gap={4}>
  //         <DashboardWidgetDisplay
  //           chartFormatting={chartData !== null && chartData}
  //           isEditableModal={isEditable}
  //         />
  //       </TabPanel>
  //       <TabPanel display={'flex'} flexDir={'column'} gap={4}>
  //         <DashboardWidgetFormat
  //           chartFormatting={chartData !== null && chartData}
  //           isEditableModal={isEditable}
  //         />
  //       </TabPanel>
  //     </TabPanels>
  //   </Tabs>
  // );
};

export default DashboardModalTabs;
