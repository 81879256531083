/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Text, Box, Flex, Tooltip } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { AppDispatch } from 'utils/GlobalHelpers';
import TrendingDown from 'components/Icons/TrendingDownIcon';
import { fetchDashboardGraph, getDashboardSelectedScenario } from 'redux/DashboardSlice';
import PriviewFilterByComponent from 'pages/PlanPageForecast/Plan/Filters/PreviewFilterBy';
import { getDate, getPreviousMonth, totalMonthsGap } from 'utils/ConvertDate';
import { getTimescaleDate } from 'redux/ModelsSlice';
import { getAllScenarios } from 'redux/ScenarioSlice';

import TimeRangeFilter from '../TimeRangeFilter';

import KPIChart from './KpiChart';

interface Properties {
  chartData: any;
  isDashboardLayout?: boolean;
}

const UNIT_MULTIPLIERS: any = {
  k: 1000,
  mn: 1_000_000,
  bn: 1_000_000_000,
  auto: 1,
};

const KPICard = ({ chartData, isDashboardLayout }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const getTimeDate = useSelector(getTimescaleDate);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;
  const [totalValues, setTotalValues] = useState<number | null>(null);
  const [valueColor, setValueColor] = useState<any>('#57519C');
  const [kpiData, setKpiData] = useState<any[]>([]);
  const [isComparision, setIsComparision] = useState(false);
  const [kpiLabel, setKpiLabel] = useState<any[]>([]);
  const [comparisionText, setComparisionText] = useState<number | string>(0);

  function getColorBasedOnCondition(array: any, valueToCheck: any) {
    let color = null;
    const displayValue = Number.parseInt(valueToCheck, 10);
    const applicableConditions = array.filter((condition: any) => {
      const conditionValue = Number.parseInt(condition.formatting.value, 10);
      switch (condition.value.toLowerCase()) {
        case 'is less than':
          return displayValue < conditionValue;
        case 'is greater than':
          return displayValue > conditionValue;
        case 'equal to':
          return displayValue === conditionValue;
        default:
          return false;
      }
    });
    if (applicableConditions.length > 0) {
      applicableConditions.sort((a: any, b: any) => a.priority - b.priority);
      color = applicableConditions[0].formatting.color;
    }
    return color;
  }

  useEffect(() => {
    if (chartData) {
      dispatch(
        fetchDashboardGraph({
          chartId: chartData?.id,
          scenarioId,
          requestBody: [],
        }),
      ).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          const totalValue = response.payload?.data?.map((item: any) => item[Object.keys(item)[0]]);
          const kpiGraphLabel = response.payload?.table?.map((item: any) => item.Time);
          const kpiGraphData = response.payload?.table?.map(
            (item: any) => item[Object.keys(item)[1]],
          );
          const comparisonValue = response.payload?.comaprison_value;

          if (chartData?.formatting?.negativeDisplay === 'brackets' && comparisonValue < 0) {
            setComparisionText(`(${Math.abs(comparisonValue)})`);
          } else {
            setComparisionText(comparisonValue);
          }
          const valueColor = getColorBasedOnCondition(chartData.conditional_formatting, totalValue);
          setValueColor(valueColor);
          setTotalValues(Math.abs(totalValue[0]));
          setKpiLabel(kpiGraphLabel);
          setKpiData(kpiGraphData);
        }
      });
    }
  }, [chartData]);

  const blockId = useMemo(() => {
    if (chartData?.instrument_indicators) {
      const firstMetric = chartData.instrument_indicators[0];
      return firstMetric ? firstMetric.block_id : null;
    }
    return null;
  }, [chartData?.instrument_indicators]);

  const isDimensionFilter = useMemo(() => {
    const filterData = chartData?.instrument_filters?.some(
      (item: any) => item?.filter_type === 'dimension',
    );
    return filterData || false;
  }, [chartData?.instrument_filters]);

  const comparisonType = useMemo(() => {
    let timeStartDate = '';
    let timeEndDate = '';
    let comparisonStartDate = '';
    let comparisonEndDate: any = '';
    const latestActuals = getPreviousMonth(new Date(getTimeDate?.startDate));
    chartData?.instrument_filters?.forEach((item: any) => {
      if (item?.name === 'Time' && item?.filter_type === 'primary') {
        timeStartDate = item?.value;
        timeEndDate = item?.second_value;
      }
      if (item.name === 'Time' && item.filter_type === 'comparision') {
        comparisonStartDate = item.value;
        comparisonEndDate = item?.second_value;
      }
    });
    setIsComparision(!!comparisonStartDate);
    const timeDate: any = getDate(timeStartDate);
    const comparisonDate: any = getDate(comparisonStartDate);
    const comparisonEndValue: any = getDate(comparisonEndDate);
    if (totalMonthsGap(timeDate, comparisonDate) === -12 && !comparisonEndValue) {
      return 'vs Single Month';
    }
    if (new Date(comparisonDate).getTime() === new Date(latestActuals).getTime()) {
      return 'vs Latest Actuals';
    }
    return comparisonEndValue ? 'vs Prior Year' : 'vs Single Month';
  }, [chartData?.instrument_filters, getTimeDate]);

  const calculateTotalValue = (value: number | null, divideValue: number) => {
    if (value !== null && value !== undefined) {
      return chartData?.formatting?.units === 'auto'
        ? `${(value / divideValue)
            .toFixed(chartData?.formatting?.decimalPlaces)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : `${(value / divideValue).toFixed(chartData?.formatting?.decimalPlaces)}${
            chartData?.formatting?.units
          }`;
    }
    return '0';
  };

  return (
    <Box
      height={'100%'}
      borderRadius='15px'
      border={isDashboardLayout ? '' : '1px solid #787878'}
      boxShadow={isDashboardLayout ? '' : '0px 4px 4px rgba(0, 0, 0, 0.25)'}
      bgGradient='linear(to-b, #FFF, #F5F5F5)'
    >
      <Text
        m={3}
        minH={8}
        fontFamily='regular'
        fontSize='2xl'
        fontWeight='extrabold'
        color='blackAlpha.900'
        textAlign='left'
        className='text-overflow'
      >
        {chartData?.instrument_indicators && (
          <>{chartData?.instrument_indicators[0]?.indicator_name || 'Indicator'}</>
        )}
      </Text>
      <Flex flexDirection='column' justifyContent='space-between' alignItems='space-between'>
        <Flex marginInline={4} mb={3} gap={3} alignItems='center'>
          {false && (
            <TimeRangeFilter chartFilters={chartData?.instrument_filters} chartId={chartData?.id} />
          )}
          {isDimensionFilter && (
            <PriviewFilterByComponent filterChartData={chartData} block_id={blockId} />
          )}
        </Flex>
        <Box marginY={3}>
          <Flex marginInline={4} justifyContent='space-between' alignItems='center' gap={5}>
            <Tooltip
              label={
                totalValues === null || totalValues === undefined || totalValues === 0
                  ? 0
                  : `£${calculateTotalValue(
                      totalValues,
                      UNIT_MULTIPLIERS[chartData?.formatting?.units],
                    )}`
              }
            >
              <Text
                flex={1}
                minWidth={0}
                w={chartData?.formatting?.sparkline ? '60%' : '100%'}
                fontSize='6xl'
                fontFamily='regular_roboto'
                fontWeight='400'
                color={valueColor || '#57519C'}
                className='text-overflow'
              >
                {totalValues === null || totalValues === undefined || totalValues === 0
                  ? 0
                  : `£${calculateTotalValue(
                      totalValues,
                      UNIT_MULTIPLIERS[chartData?.formatting?.units],
                    )}`}
              </Text>
            </Tooltip>
            {chartData?.formatting?.sparkline && (
              <>
                <Box w='40%'>
                  <KPIChart kpiLabelData={kpiLabel} kpiChartData={kpiData} />
                </Box>
              </>
            )}
          </Flex>
        </Box>
        {isComparision && (
          <Flex m={2} mt={0} alignItems='center' h={5} gap={2}>
            <TrendingDown />
            <Text color='blackAlpha.900'>
              <b>{`${comparisionText}% `}</b>
              {`${comparisonType}`}
            </Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default KPICard;
